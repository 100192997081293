import React, { useEffect, useState } from 'react'
import SideBar from '../components/SideBar'
import * as API from "../utils/api";
import swal from 'sweetalert';
import { TextField, Button } from '@mui/material'
import { Container } from 'react-bootstrap';


function PageSettingsEmail() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [host, setHost] = useState("")
    const [port, setPort] = useState("")
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        API.GetAPI('/api/email/smtp').then((response) => {
            if (response) {
                setEmail(response.smtp_email)
                setPassword(response.smtp_pass)
                setHost(response.smtp_host)
                setPort(response.smtp_port)
            }

        });
    }, [])


    const onSave = async (e) => {
        e.preventDefault();
        const body = {
            smtp_email: email,
            smtp_pass: password,
            smtp_host: host,
            smtp_port: port
        }
        try {
            setLoading(true);
            const res = await API.PostAPI('/api/email/smtp', body);
            swal(res.message);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }


    return (
        <SideBar>
            <Container>
                <h1>Email SMTP Settings</h1>
                <form onSubmit={onSave}>
                    <br /> <br />
                    <TextField required label="Email" value={email} onChange={e => setEmail(e.target.value)} sx={{ width: "100%" }} type='email' name="email" />
                    <br /> <br />
                    <TextField required label="Password" value={password} onChange={e => setPassword(e.target.value)} sx={{ width: "100%" }} type='password' name="password" />
                    <br /> <br />
                    <TextField required label="Out Going Host Name" value={host} onChange={e => setHost(e.target.value)} sx={{ width: "100%" }} name="host" />
                    <br /> <br />
                    <TextField required label="Port" value={port} onChange={e => setPort(e.target.value)} sx={{ width: "100%" }} name="port" />
                    <br /> <br />
                    <Button disabled={loading} variant="contained" color="primary" type="submit">SAVE SMTP EMAIL SETTINGS</Button>
                </form>
            </Container>
        </SideBar>
    )
}

export default PageSettingsEmail
