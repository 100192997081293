import React, { memo, useEffect, useState, lazy, Suspense } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { Stack, Skeleton } from '@mui/material';
import swal from 'sweetalert';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const UserImage = lazy(() => import('../components/UserImage'));

const userStatusIcon = {
	'Paid User': (
		<img src="/images/businessman_red.png" width="25" height="30" alt="businessman" />
	),
	'Paid Affiliate': (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1792 1792"
			width="25"
			height="25"
		>
			<path
				d="M522 883q-74-162-74-371H192v96q0 78 94.5 162T522 883zm1078-275v-96h-256q0 209-74 371 141-29 235.5-113t94.5-162zm128-128v128q0 71-41.5 143t-112 130-173 97.5T1186 1023q-42 54-95 95-38 34-52.5 72.5T1024 1280q0 54 30.5 91t97.5 37q75 0 133.5 45.5T1344 1568v64q0 14-9 23t-23 9H480q-14 0-23-9t-9-23v-64q0-69 58.5-114.5T640 1408q67 0 97.5-37t30.5-91q0-51-14.5-89.5T701 1118q-53-41-95-95-113-5-215.5-44.5t-173-97.5-112-130T64 608V480q0-40 28-68t68-28h288v-96q0-66 47-113t113-47h576q66 0 113 47t47 113v96h288q40 0 68 28t28 68z"
				fill="#e6be1c"
				className="fill-000000"
			/>
		</svg>
	),
	'Free Affiliate': (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			xmlSpace="preserve"
			width="35"
			height="35"
		>
			<path
				d="M285.213 212.865h53.154s2.903-63.743-62.294-72.879V115.9h1.038-25.33v23.465s-82.846 4.569-62.08 86.789c0 0 3.313 21.178 42.77 41.53 0 0 48.581 17.856 47.338 26.991 0 0 18.577 37.375-21.855 39.867 0 0-27.348-2.701-27.348-36.649H177.34s-3.736 68.52 70.705 75.374v22.423h25.221V374.2s55.136-1.871 63.541-55.126c0 0 13.397-50.769-58.548-78.797 0 0-39.562-12.461-39.249-34.883 0 0-1.246-21.18 13.39-24.607-.001-.001 31.674-11.833 32.813 32.078z"
				fill="#18ab1f"
				className="fill-000000"
			/>
		</svg>
	),
};

function AffiliatePill({ selectedSet, user, tier, onClick, affiliateData, onSelected }) {
	const {
		status,
		image,
		free_user_count,
		paid_user_count,
		free_affiliate_count,
		paid_affiliate_count,
		total_subusers,
		name,
		facebook,
		google,
		app,
		email,
		email_full,
	} = user;

	const [margin, setMargin] = useState();
	const [childTopShift, setChildTopShift] = useState();
	const [tier2Count, setTier2Count] = useState(0);
	const [greyOut, setGreyOut] = useState(false);

	useEffect(() => {
		let total = 0;
		(user?.subusers || []).forEach(u => total += u.subusers.length)
		setTier2Count(total);
	}, [user])

	useEffect(() => {

		if (affiliateData) {
			const tier1Selected = affiliateData.find(a => a.id === 1).checked;
			const tier2Selected = affiliateData.find(a => a.id === 2).checked;


			if (tier1Selected && tier !== 0) setGreyOut(true);
			if (tier2Selected && tier !== 1) setGreyOut(true);
			if (tier1Selected && tier2Selected && (tier > 1)) setGreyOut(true);
			if (!tier1Selected && !tier2Selected) setGreyOut(false);


		}

		setMargin(tier * 220);
		setChildTopShift(-50 * tier);
	}, [tier, affiliateData]);

	function onCopyEmail() {
		window.navigator.clipboard.writeText(email_full).then(() => {
			swal('Email Copied', `You've copied ${name}'s email`, 'success');
		});
	}

	// if (greyOut) {
	// 	return (
	// 		<div style={{
	// 			position: 'relative',
	// 			top: childTopShift,
	// 			width:
	// 				status === 'Paid User' ||
	// 					status === 'Free Affiliate' ||
	// 					status === 'Paid Affiliate'
	// 					? 235 : 205,
	// 			height: 50,
	// 			marginLeft: margin,
	// 			backgroundColor: '#3b3b3b',
	// 			marginTop: '5px',
	// 			marginBottom: '5px',
	// 			padding: '2px',
	// 			display: 'flex',
	// 			flexDirection: 'row',
	// 			alignItems: 'center',
	// 			borderRadius: '50px',
	// 		}}>

	// 			<div
	// 				style={{
	// 					backgroundColor: '#fafafa',
	// 					width: '24px',
	// 					height: '24px',
	// 					borderRadius: '50%',
	// 				}}>
	// 				<div
	// 					style={{ borderRadius: "50%", width: 30, height: 30, backgroundColor: "lightgray" }}
	// 				/>
	// 			</div>
	// 		</div>
	// 	)
	// }

	return (
		<div

			className="dropShadow round hover"
			style={{
				position: 'relative',
				top: childTopShift,
				width:
					status === 'Paid User' ||
						status === 'Free Affiliate' ||
						status === 'Paid Affiliate'
						? 235
						: 205,
				height: 45,
				marginLeft: margin,
				backgroundColor: '#f9f9f9',
				marginTop: '5px',
				marginBottom: '5px',
				padding: '2px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				borderRadius: '50px',
			}}
		>
			<div
				style={{
					backgroundColor: '#f3f3f3',
					width: '24px',
					height: '24px',
					borderRadius: '50%',
					position: 'relative',
				}}
				onClick={onSelected}
			>
				<LazyLoadImage
					src={app ? app : "images/thrivecart.png"}
					width="24"
					height="24"
					alt="app logo"
					placeholder={<Skeleton variant="circular" width={24} height={24} />}
				/>
				{selectedSet.has(user._id) ?
					<img
						style={{ borderRadius: "50%", position: "relative", top: -45 }}
						src="images/checkbox_green.png"
						width={20}
						alt="selected"
						title={user.email}
						name={user?.notification_id || "none"}
						id={user._id}
					/> : null}
			</div>
			<div
				onClick={onSelected}
				style={{
					marginLeft: '5px',
					backgroundColor: '#f3f3f3',
					width: '42px',
					height: '42px',
					borderRadius: '50%',
				}}
			>
				<Suspense
					fallback={<Skeleton variant="circular" width={42} height={42} />}
				>
					<UserImage image={image} name={name} size={42} color="lightgray" />



				</Suspense>
			</div>
			<div
				style={{
					flexGrow: '1',
					display: 'flex',
					flexDirection: 'column',
					aligntItems: 'flex-start',
					marginLeft: '10px',
				}}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<div>
						{google && (
							<span>
								<FcGoogle size={10} />
							</span>
						)}
						{facebook && (
							<span style={{ marginLeft: '10px' }}>
								<FaFacebook size={10} />{' '}
							</span>
						)}
						{!google && !facebook && (
							<div
								style={{
									height: 20,
									width: 8,
									marginTop: 5,
								}}
							></div>
						)}
					</div>
					<Stack
						direction="row"
						spacing={1}
						style={{
							lineHeight: 0,
							fontSize: '10px',
						}}
					>
						<div
							style={{
								width: '30px',
								height: '10px',
								backgroundColor: '#FF557F',
								color: '#fff',
								borderRadius: '40px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '8px',
								fontWeight: 'bold',
							}}
						>
							T1-{user?.subusers?.length || ""}
						</div>
						<div
							style={{
								width: '30px',
								height: '10px',
								backgroundColor: '#A027E7',
								color: '#fff',
								borderRadius: '40px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '8px',
								fontWeight: 'bold',
							}}
						>
							T2-{tier2Count}
						</div>
					</Stack>
				</Stack>

				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={
						<Tooltip id="text1">
							<div>
								<h6>
									Click to copy <strong>Email</strong>
								</h6>
								<h5>{name}</h5>
								<h6>
									<strong >{email}</strong>
								</h6>
							</div>
						</Tooltip>
					}>
					<div style={{ cursor: "pointer" }} onClick={onCopyEmail} >
						<h6 style={{ lineHeight: 0, fontSize: '10px', fontWeight: 'bold', }}>
							{name.length > 20 ? name.substring(0, 18) + "..." : name}
						</h6>
					</div>
				</OverlayTrigger>



				<Stack
					direction="row"
					spacing={1}
					style={{
						marginBottom: '4px',
						lineHeight: 0,
						fontSize: '10px',
					}}
				>
					<Badge pill variant="primary">
						{free_user_count}
					</Badge>
					<Badge pill variant="danger">
						{paid_user_count}
					</Badge>
					<Badge pill variant="warning">
						{free_affiliate_count}
					</Badge>
					<Badge pill variant="success">
						{paid_affiliate_count}
					</Badge>
				</Stack>
			</div>
			{(status === 'Paid User' ||
				status === 'Free Affiliate' ||
				status === 'Paid Affiliate') && (
					<div
						style={{
							width: '15%',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						{userStatusIcon[status]}
					</div>
				)}
			<div
				style={{
					width: '10%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'start',
				}}
			>
				<div
					onClick={onClick}
					style={{
						width: '30px',
						height: '15px',
						backgroundColor: 'crimson',
						color: '#fff',
						borderRadius: '40px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: '10px',
						fontWeight: 'bold',
						cursor: 'pointer'
					}}
				>
					{total_subusers}
				</div>
			</div>
		</div>
	);
}

export default memo(AffiliatePill);
