import React, { useState } from 'react'
import { FaBandcamp, FaPowerOff } from 'react-icons/fa';
import {  MdMoreVert } from 'react-icons/md';
import { BiStats, BiBell } from 'react-icons/bi';
import { IoIosPeople } from 'react-icons/io';
import { HiSpeakerphone } from 'react-icons/hi';
import { AiFillAppstore } from 'react-icons/ai';
import { Container, Button, Modal, Row, Col } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import * as API from "../utils/api";


function MobileBar() {
    
    const [popup, setPopup] = useState(false);
    const router = useNavigate();
    const buttonWidth = "80%";
    const getSelectedColor = (path) => "#E6E6E6";

    const style = {
        display: "flex",
        background: "#292929",
        position: "fixed",
        width: "100%",
        height: "40px",
        bottom: 0,
        left: 0,
        zIndex: 9999,
        color: 'ghostwhite',
        padding: 7,
        justifyContent: "center"

    }

    const onLogout = () => {
        Cookies.set(API.REACT_APP_COOKIE_KEY, null);
        router("/");
        // swal("Logout", 'Come back again', 'success');
    }


    return (
        <div className="centralise dropShadow" style={style}>
            <div style={{ paddingLeft: 10 }} onClick={() => router('/apps')}>
                <AiFillAppstore color={getSelectedColor('apps')} size={20} />
            </div>
            <div style={{ paddingLeft: 25 }} onClick={() => router('/affiliates')}>
                <IoIosPeople color={getSelectedColor('affiliates')} size={20} />
            </div>
            <div style={{ paddingLeft: 25 }} onClick={() => router('/campaign')}>
                <FaBandcamp color={getSelectedColor('campaign')} size={20} />
            </div>
            <div style={{ paddingLeft: 25 }} onClick={() => router('/statistics')}>
                <BiStats color={getSelectedColor('statistics')} size={20} />
            </div>
            <div style={{ paddingLeft: 25 }} onClick={() => router('/notifications')}>
                <BiBell color={getSelectedColor('notifications')} size={20} />
            </div>
            <div style={{ paddingLeft: 25 }} onClick={() => setPopup(true)}>
                <MdMoreVert size={20} />
            </div>

            <Modal className="centralise" style={{ width: "70%" }} show={popup} onHide={() => setPopup(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><h6>More Options</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="centralise">
                        <Row xs={1}>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" onClick={() => { }}>
                                    <HiSpeakerphone />
                                    <strong>Integrations</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" onClick={onLogout}>
                                    <FaPowerOff />
                                    <strong>Logout</strong>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MobileBar