

import React, { useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import { TextField } from '@mui/material';


const logo = "../images/logo.png";


function PagePassword() {

    const [loading, setLoading] = useState(false);

    const onEmail = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const user = {
                email: e.target.email.value,
            }

            const result = await API.PostAPI('/api/email/forgotPassword', user);
            if (result) {
                swal('Password Reset', 'Password was reset check your email', 'success');
            } else {
                swal('Password Reset', 'Something happened. Check your internet connection', 'info');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container style={{ paddingTop: 40, paddingBottom: 40 }} className="centralise" fluid>

            <img src={logo} alt="logo" width={250} />
            {loading ?
                <div className="app"><AnimationLoading width={250} title="Loading" /></div>

                :
                <div>
                    <br />
                    <h1>Forgot Password</h1>
                    <br />
                    <Form onSubmit={onEmail}>
                        <TextField style={{ width: 400 }} label="Your Email" type="email" required name="email" aria-label="email" />
                        <br /><br />
                        <Button style={{ width: 400 }} disabled={loading} type="submit" variant="light" className="round hover dropShadow">
                            <img src="https://img.icons8.com/fluency/24/000000/email.png" alt="Email" />
                            {" "}
                            <strong>Reset Password</strong>
                        </Button>
                        <br /> <br />
                        <h6>Back to <Link to="/account">Logging in</Link></h6>
                    </Form>
                </div>
            }

            <br /><br /><br /><br />
        </Container>
    )
}

export default PagePassword
