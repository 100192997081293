import React, { useEffect, useState } from 'react'
import * as API from './utils/api';
import * as DASHBOARD from "./utils/dashboard";
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import PageLogin from "./pages/PageLogin";
import PageStatistics from './pages/PageStatistics';
import PageSignUpEmail from './pages/PageSignup';
import PageAffiliates from './pages/PageAffiliates';
import PageCampaign from './pages/PageCampaign';
import PageOAuth from './pages/PageOauth';
import PageOAuth2 from './pages/PageOauth2';
import PageSettingsEmail from './pages/PageSettingsEmail';
import PageUsers from './pages/PageUsers';
import PagePassword from './pages/PagePassword';
import PageNotifications from './pages/PageNotifications';
import PageApps from './pages/PageApps';



export const ContextUser = React.createContext(null);
export const ContextSetUser = React.createContext(null);
export const ContextRegisteredApps = React.createContext([]);
export const ContextSetRegisteredApps = React.createContext(null);
export const ContextCollapse = React.createContext(true);
export const ContextSetCollapse = React.createContext((value) => { });
export const ContextApps = React.createContext([]);
export const ContextSetApps = React.createContext((apps) => { });
export const ContextAllNetworks = React.createContext([]);
export const ContextAllUsers = React.createContext([]);


function App() {

  const [apps, setApps] = useState([]);
  const [user, setUser] = useState(null);
  const [registeredApps, setRegisteredApps] = useState([]);
  const [collapse, setCollapse] = useState(true);
  const [allNetworks, setAllNetworks] = useState(null);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    async function run() {
      try {
        const apps = await API.GetAPI('/api/apps');
        setApps(apps ? apps : []);
      } catch (e) {
        console.log(e.message);
      }
    }
    console.log('Running')
    run();
  }, []);

  useEffect(() => {
    async function run() {
      try {
        const res = await API.GetAPI('/api/user');
        const user = res.user;

        if (user) {
          const registered = await DASHBOARD.getRegisteredApps(user.email);
          setRegisteredApps(registered);
          setUser(user);
          console.log('Registered Apps Loaded');
        }
      } catch (e) {
        console.log(e.message);
      }
    }
    run();
  }, [])

  useEffect(() => {
    async function run() {
      try {
        const res = await API.GetAPI('/api/user');
        const user = res.user;

        if (user) {
          const referrals = await DASHBOARD.getReferrals(user.email);
          setAllNetworks(referrals ? referrals : []);
          setAllUsers(getOneBigArray(referrals ? referrals : []));
          console.log('Referrals Loaded');
        }
      } catch (e) {
        console.log(e.message);
      }
    }

    run();


    function getOneBigArray(allNetworks) {
      //declare arrays
      const list = [];
      const initialUsers = [];

      //get all the app ids
      const appIds = Object.keys(allNetworks);

      //get all the users for each app
      appIds.forEach((appId) => initialUsers.push(...allNetworks[appId]));

      //get all the subusers and inner subusers
      initialUsers.forEach((user) => recursiveGetSubusers(user, 1));

      //push initial users
      initialUsers.forEach((u) => (u.tier = 0));
      list.push(...initialUsers);

      function recursiveGetSubusers(user, tier) {
        user.subusers.forEach((u) => (u.tier = tier));
        list.push(...user.subusers);
        user.subusers.forEach((u) => recursiveGetSubusers(u, tier + 1));
      }

      //sort data alphabetically
      return list.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }
  }, [])


  return (
    <ContextAllNetworks.Provider value={allNetworks}>
      <ContextAllUsers.Provider value={allUsers}>

        <ContextApps.Provider value={apps}>
          <ContextSetApps.Provider value={setApps}>

            <ContextCollapse.Provider value={collapse}>
              <ContextSetCollapse.Provider value={setCollapse}>

                <ContextUser.Provider value={user}>
                  <ContextSetUser.Provider value={setUser}>
                    <ContextRegisteredApps.Provider value={registeredApps}>
                      <ContextSetRegisteredApps.Provider value={setRegisteredApps}>
                        <Router>
                          <Routes>
                            <Route index path="/" element={<PageLogin />} />
                            <Route path="/apps" element={<PageApps />} />
                            <Route path="/affiliates" element={<PageAffiliates />} />
                            <Route path="/campaign" element={<PageCampaign />} />
                            <Route path="/notification" element={<PageNotifications />} />
                            <Route path="/oauth" element={<PageOAuth />} />
                            <Route path="/oauth2" element={<PageOAuth2 />} />
                            <Route path="/password" element={<PagePassword />} />
                            <Route path="/settings-email" element={<PageSettingsEmail />} />
                            <Route path="/sigmup" element={<PageSignUpEmail />} />
                            <Route path="/statistics" element={<PageStatistics />} />
                            <Route path="/users" element={<PageUsers />} />
                          </Routes>
                        </Router>
                      </ContextSetRegisteredApps.Provider>
                    </ContextRegisteredApps.Provider>
                  </ContextSetUser.Provider>
                </ContextUser.Provider>

              </ContextSetCollapse.Provider>
            </ContextCollapse.Provider>

          </ContextSetApps.Provider>
        </ContextApps.Provider>

      </ContextAllUsers.Provider>
    </ContextAllNetworks.Provider>
  );
}

export default App;
