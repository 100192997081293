import React, { useCallback, useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { ContextAllNetworks, ContextAllUsers, ContextRegisteredApps, ContextUser } from '../App';
import { useContext } from 'react';
import AffiliatePill from '../components/AffiliatePill';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Box, Stack, Button, ButtonGroup, Chip, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { AiOutlineMail } from 'react-icons/ai';
import { BsPlusLg } from 'react-icons/bs';
import { BiMinus } from 'react-icons/bi';
import PopupComponet from '../components/PopupComponent';
import { AnimationLoading } from '../components/Lottie';
import * as API from '../utils/api';
import swal from 'sweetalert';
import { Link } from 'react-router-dom'
import * as THRIVECART from '../utils/thrivecart.js';


const Businessman = 'images/businessman_white.png';
const CheckboxBlankIcon = 'images/checkbox_fill.png';
const CheckboxIcon = 'images/checkbox_green.png';

function ContentAffiliateNetwork() {


	const allNetworks = useContext(ContextAllNetworks)
	const allUsers = useContext(ContextAllUsers);
	const [thrivecartAffiliates, setThrivecartAffiliates] = useState([]);

	const [loadingMessage, setLoadingMessage] = useState(false);
	const [loading, setLoading] = useState(false);

	const [unFiltedUser, setUnFiltedUsers] = useState([]);
	const [unFilteredAllUsers, setUnFilteredAllUsers] = useState([]);
	const registeredApps = useContext(ContextRegisteredApps);
	const [currentApp, setCurrentApp] = useState('all');
	const [users, setUsers] = useState([]);
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');
	const [apps, setApps] = useState([]);

	const profile = useContext(ContextUser);


	// Notification and Email for selected people
	const [selectPopup, setSelectPopup] = useState(false);
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState("");
	const [url, setUrl] = useState("");
	const [image, setImage] = useState("");
	const [buttonURL1, setButtonURL1] = useState("");
	const [buttonURL2, setButtonURL2] = useState("");
	const [button1, setButton1] = useState("");
	const [button2, setButton2] = useState("");

	// Email
	const [emailTitle, setEmailTitle] = useState("");
	const [emailSubject, setEmailSubject] = useState("");
	const [emailHTML, setEmailHTML] = useState("");

	const [isNotification, setIsNotification] = useState(true);
	const [isEmail, setIsEmail] = useState(false);

	const [selectedPeople, setSelectedPeople] = useState(false);
	const [selectedSet, setSelectedSet] = useState(new Set());


	const handleOpen = () => selectedPeople ? setSelectPopup(true) : setOpen(true);
	const handleClose = () => selectedPeople ? setSelectPopup(false) : setOpen(false);

	const [affiliateData, setAffiliateData] = useState([
		{
			id: 1,
			checked: false,
			label: 'Tier 1',
		},
		{
			id: 2,
			checked: false,
			label: 'Tier 2',
		},
		{
			id: 3,
			checked: false,
			label: 'Free User',
		},
		{
			id: 4,
			checked: false,
			label: 'Paid User',
		},
		{
			id: 5,
			checked: false,
			label: 'Free Affiliate',
		},
		{
			id: 6,
			checked: false,
			label: 'Paid Affiliate',
		},
	]);

	// Get all thrivecart affiliate
	useEffect(() => {
		async function run() {
			try {
				const list = await THRIVECART.getAllAffiliates(profile.email);
				if (list && list.length) setThrivecartAffiliates(list);
			} catch (e) {
				console.log(e.message);
			}
		}
		if (profile) run();
	}, [profile])



	useEffect(() => {
		const list = Object.keys(registeredApps)
			.filter(name => registeredApps[name])
			.map((name) => {
				let n = name;
				if (n == 'fbscraper') n = 'SOCIAL PROSPECTOR';
				else if (n == 'fbgrouphacker') n = 'GROUP MASTER';
				return {
					name: n.toUpperCase(),
					id: name,
					_id: name,
				}
			})
			.sort((a, b) => a.name.localeCompare(b.name))
		setApps(list);
	}, [registeredApps])


	// useEffect(() => {
	// 	async function run() {
	// 		try {
	// 			setLoading(true);
	// 			const referrals = await DASHBOARD.getReferrals(user.email);
	// 			setAllNetworks(referrals ? referrals : []);
	// 			setAllUsers(getOneBigArray(referrals ? referrals : []));
	// 		} catch (e) {
	// 			console.log(e.message);
	// 		} finally {
	// 			setLoading(false);
	// 		}
	// 	}

	// 	function getOneBigArray(allNetworks) {
	// 		//declare arrays
	// 		const list = [];
	// 		const initialUsers = [];

	// 		//get all the app ids
	// 		const appIds = Object.keys(allNetworks);

	// 		//get all the users for each app
	// 		appIds.forEach((appId) => initialUsers.push(...allNetworks[appId]));

	// 		//get all the subusers and inner subusers
	// 		initialUsers.forEach((user) => recursiveGetSubusers(user, 1));

	// 		//push initial users
	// 		initialUsers.forEach((u) => (u.tier = 0));
	// 		list.push(...initialUsers);

	// 		function recursiveGetSubusers(user, tier) {
	// 			user.subusers.forEach((u) => (u.tier = tier));
	// 			list.push(...user.subusers);
	// 			user.subusers.forEach((u) => recursiveGetSubusers(u, tier + 1));
	// 		}

	// 		//sort data alphabetically
	// 		return list.sort(function (a, b) {
	// 			if (a.name < b.name) return -1;
	// 			if (a.name > b.name) return 1;
	// 			return 0;
	// 		});
	// 	}

	// 	run();
	// }, []);


	useEffect(() => {
		if (currentApp === 'all') {
			const list = []
			apps.forEach(app => {
				const id = app.id.replace(/\s/gim, '').toLowerCase();
				if (allNetworks) {
					const sublist = allNetworks[id];
					if (sublist) {
						list.push(...sublist);
					}
				}

			});
			setUsers(list ? list : []);
			setUnFiltedUsers(list ? list : []);
		} else if (currentApp !== '-1') {
			if (allNetworks) {
				const list = allNetworks[currentApp.toLowerCase()];
				setUsers(list ? list : []);
				setUnFiltedUsers(list ? list : []);
			}

		} else {
			setUsers([]);
			setUnFiltedUsers([]);
		}
	}, [apps, currentApp, allNetworks]);


	const handleChipBtn = useCallback(
		(e) => {
			if (e.target.innerText === 'Tier 1')
				setAffiliateData(
					affiliateData.map((item) =>
						item.id === 1 ? { ...item, checked: !item.checked } : item
					)
				);

			if (e.target.innerText === 'Tier 2')
				setAffiliateData(
					affiliateData.map((item) =>
						item.id === 2 ? { ...item, checked: !item.checked } : item
					)
				);

			if (e.target.innerText === 'Free User')
				setAffiliateData(
					affiliateData.map((item) =>
						item.id === 3 ? { ...item, checked: !item.checked } : item
					)
				);

			if (e.target.innerText === 'Paid User')
				setAffiliateData(
					affiliateData.map((item) =>
						item.id === 4 ? { ...item, checked: !item.checked } : item
					)
				);

			if (e.target.innerText === 'Free Affiliate')
				setAffiliateData(
					affiliateData.map((item) =>
						item.id === 5 ? { ...item, checked: !item.checked } : item
					)
				);
			if (e.target.innerText === 'Paid Affiliate')
				setAffiliateData(
					affiliateData.map((item) =>
						item.id === 6 ? { ...item, checked: !item.checked } : item
					)
				);
		},
		[affiliateData]
	);


	const applyFilter = useCallback(() => {
		let appliedUsersFilter = unFiltedUser;
		// let appliedAllUsersFilter = unFilteredAllUsers;
		// const checkedItems = affiliateData
		// 	.filter((item) => item.checked === true)
		// 	.map((item) => item.label);

		// if (checkedItems.length > 0) {
		// 	appliedUsersFilter = appliedUsersFilter.filter(
		// 		(user) =>
		// 			checkedItems.includes(user.status) ||
		// 			checkedItems.includes(user.subusers.status) ||
		// 			(user.subusers.subusers &&
		// 				checkedItems.includes(user.subusers.subusers.status))
		// 	);
		// 	appliedAllUsersFilter = appliedAllUsersFilter.filter((item) =>
		// 		checkedItems.includes(item.status)
		// 	);
		// }

		setUsers(appliedUsersFilter);
		// setAllUsers(appliedAllUsersFilter);
	}, [unFiltedUser]);


	useEffect(() => {
		applyFilter();
	}, [affiliateData, applyFilter]);


	function searchFilter(user) {
		return (
			search.replace(/\s/gim, '') === '' ||
			user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
			user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
		);
	}

	function findAndToggleChildren(indices) {
		console.log('clicked toogle', indices);

		const firstUser = users.filter(searchFilter)[indices[0]];
		const index = users.indexOf(firstUser);
		indices[0] = index;

		console.log(firstUser)

		if (indices.length === 1) {
			const show = users[indices[0]].showChildren;

			//toggle show children
			if (show) {
				users[indices[0]].showChildren = false;
				setUsers([...users]);
			} else {
				users[indices[0]].showChildren = true;
				setUsers([...users]);
			}
		} else if (indices.length === 2) {
			const show = users[indices[0]].subusers[indices[1]].showChildren;

			//toggle show children
			if (show) {
				users[indices[0]].subusers[indices[1]].showChildren = false;

				setUsers([...users]);
			} else {
				users[indices[0]].subusers[indices[1]].showChildren = true;

				setUsers([...users]);
			}
		} else if (indices.length === 3) {
			const show =
				users[indices[0]].subusers[indices[1]].subusers[indices[2]]
					.showChildren;

			//toggle show children
			if (show) {
				users[indices[0]].subusers[indices[1]].subusers[
					indices[2]
				].showChildren = false;

				setUsers([...users]);
			} else {
				users[indices[0]].subusers[indices[1]].subusers[
					indices[2]
				].showChildren = true;

				setUsers([...users]);
			}
		} else if (indices.length === 4) {
			const show =
				users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[
					indices[3]
				].showChildren;

			//toggle show children
			if (show) {
				users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[
					indices[3]
				].showChildren = false;

				setUsers([...users]);
			} else {
				users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[
					indices[3]
				].showChildren = true;

				setUsers([...users]);
			}
		}
	}

	const onSendNotification = async () => {
		try {

			const people = allUsers.filter(u => selectedSet.has(u._id)).map(user => {
				const app = apps.find(app => app.name.replace(/\s/gmi, '').toLowerCase() === user.from.replace(/\s/gmi, '').toLowerCase())

				const url = app?.base_url || `https://app.mysocial360.com/${app.id}`
				return {
					name: user.name,
					image: user.image,
					_id: user._id,
					id: user._id,
					email: user.email_full || user.email,
					app_image: user.app,
					app: url,
					app_name: user.from,
					notification_id: user.notification_id
				}
			});


			setLoadingMessage(true);
			if (isNotification) {
				if (title.replace(/\s/gmi) === '') return swal('Sending Notification', 'Please enter a title', 'warning');
				if (message.replace(/\s/gmi) === '') return swal('Sending Notification', 'Please enter a message', 'warning');

				const body = {
					title, message, url, image, button1, button2, buttonURL1, buttonURL2, people
				}
				const res = await API.PostAPI(`/api/notifications/send/messages`, body)
				swal('Sending Notifications', res.message, res.result ? 'success' : 'error');
			}


			setSelectPopup(false);


			// Send Emails
			if (isEmail) {

				if (emailTitle.replace(/\s/gmi) === '') return swal('Sending Emails', 'Please enter a title', 'warning');
				if (emailHTML.replace(/\s/gmi) === '') return swal('Sending Emails', 'Please enter a message', 'warning');

				const body = {
					title: emailTitle,
					subject: emailSubject,
					html: emailHTML,
					emails: people.map(p => p.email)
				}
				const res = await API.PostAPI(`/api/email/send`, body)
				swal('Sending Emails', res.message, res.result ? 'success' : 'error');
			}


		} catch (e) {
			console.log(e);
		} finally {
			setLoadingMessage(false);
		}
	}


	function onSelectUser(indices = []) {


		if (!indices.length) return;


		// Reconfigure the indices base from filter
		for (const i in indices) {
			const u = users.filter(searchFilter)[indices[i]];
			const index = users.indexOf(u);
			indices[i] = index;
		}

		// update user object to toggle selection;
		if (indices.length == 1) {

			if (users[indices[0]].selected || selectedSet.has(users[indices[0]]._id)) {
				users[indices[0]].selected = false;
				selectedSet.delete(users[indices[0]]._id);
			} else {
				users[indices[0]].selected = true;
				selectedSet.add(users[indices[0]]._id);
			}

			for (const i in users[indices[0]].subusers) {
				if (users[indices[0]].subusers[i].selected) {
					users[indices[0]].subusers[i].selected = false;
					selectedSet.delete(users[indices[0]].subusers[i]._id);
				} else {
					users[indices[0]].subusers[i].selected = true;
					selectedSet.add(users[indices[0]].subusers[i]._id);
				}

			}
			setUsers([...users]);
		} else if (indices.length == 2) {

			if (users[indices[0]].subusers[indices[1]].selected || selectedSet.has(users[indices[0]].subusers[indices[1]]._id)) {
				users[indices[0]].subusers[indices[1]].selected = false
				selectedSet.delete(users[indices[0]].subusers[indices[1]]._id);
			} else {
				users[indices[0]].subusers[indices[1]].selected = true
				selectedSet.add(users[indices[0]].subusers[indices[1]]._id);
			}

			for (const i in users[indices[0]].subusers[indices[1]].subusers) {
				if (users[indices[0]].subusers[indices[1]].subusers[i].selected) {
					users[indices[0]].subusers[indices[1]].subusers[i].selected = false;
					selectedSet.delete(users[indices[0]].subusers[indices[1]].subusers[i]._id);
				} else {
					users[indices[0]].subusers[indices[1]].subusers[i].selected = true;
					selectedSet.add(users[indices[0]].subusers[indices[1]].subusers[i]._id);
				}
			}


			setUsers([...users]);
		} else if (indices.length == 3) {
			// Select Main user
			if (users[indices[0]].subusers[indices[1]].subusers[indices[2]].selected || selectedSet.has(users[indices[0]].subusers[indices[1]].subusers[indices[2]]._id)) {
				users[indices[0]].subusers[indices[1]].subusers[indices[2]].selected = false;
				selectedSet.delete(users[indices[0]].subusers[indices[1]].subusers[indices[2]]._id);
			} else {
				users[indices[0]].subusers[indices[1]].subusers[indices[2]].selected = true;
				selectedSet.add(users[indices[0]].subusers[indices[1]].subusers[indices[2]]._id);
			}

			// Select downline
			for (const i in users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers) {
				if (users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[i].selected) {
					users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[i].selected = false;
					selectedSet.delete(users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[i]._id);
				} else {
					users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[i].selected = true;
					selectedSet.add(users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[i]._id);
				}
			}

			setUsers([...users]);


		} else if (indices.length == 4) {

			// Select Main user
			if (users[indices[0]].subusers[indices[1]]
				.subusers[indices[2]]
				.subusers[indices[3]]
				.selected || selectedSet.has(users[indices[0]].subusers[indices[1]]
					.subusers[indices[2]]
					.subusers[indices[3]]._id)) {
				users[indices[0]].subusers[indices[1]]
					.subusers[indices[2]]
					.subusers[indices[3]]
					.selected = false;

				selectedSet.delete(
					users[indices[0]].subusers[indices[1]]
						.subusers[indices[2]]
						.subusers[indices[3]]._id
				);

			} else {
				users[indices[0]].subusers[indices[1]]
					.subusers[indices[2]]
					.subusers[indices[3]]
					.selected = true;

				selectedSet.add(
					users[indices[0]].subusers[indices[1]]
						.subusers[indices[2]]
						.subusers[indices[3]]._id
				);
			}


			// Select downline
			for (const i in users[indices[0]].subusers[indices[1]].subusers[indices[2]].subusers[indices[3]].subusers) {
				if (users[indices[0]]
					.subusers[indices[1]]
					.subusers[indices[2]]
					.subusers[indices[3]]
					.subusers[i].selected) {
					users[indices[0]]
						.subusers[indices[1]]
						.subusers[indices[2]]
						.subusers[indices[3]]
						.subusers[i].selected = false;

					selectedSet.delete(
						users[indices[0]]
							.subusers[indices[1]]
							.subusers[indices[2]]
							.subusers[indices[3]]
							.subusers[i]._id
					);
				} else {
					users[indices[0]]
						.subusers[indices[1]]
						.subusers[indices[2]]
						.subusers[indices[3]]
						.subusers[i].selected = true;

					selectedSet.add(
						users[indices[0]]
							.subusers[indices[1]]
							.subusers[indices[2]]
							.subusers[indices[3]]
							.subusers[i]._id
					);
				}
			}

			setUsers([...users]);
		}

		// Configure Selection
		const allset = new Set();
		for (const id of Array.from(selectedSet.values())) allset.add(id);
		setSelectedSet(allset);

		// setSelectedPeople(document.querySelector('img[alt="selected"]') ? true : false)
		setSelectedPeople(allset.size ? true : false);
	}

	if (registeredApps.length === 0 || allNetworks === null) {
		return (
			<Container>
				<AnimationLoading title="Loading Users (This might take a minute)" />
			</Container>
		)
	}


	return (
		<>
			<Container>
				<Stack direction="row" spacing={2}>
					<span className="select-wrapper">
						<select
							value={currentApp}
							onChange={(e) => setCurrentApp(e.target.value)}
						>
							<option value="-1" disabled>
								Select App
							</option>
							<option value="all">ALL APPS</option>
							{apps.map((app) => (
								<option
									key={app._id}
									value={app.name.replace(/\s/gim, '').toLowerCase()}
								>
									{app.name}
								</option>
							))}
						</select>
					</span>
					<Stack direction="row" spacing={3}>
						<Chip
							sx={{
								height: '28px',
								borderRadius: '50px',
								backgroundColor: '#AF125A',
							}}
							label={
								<>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											width: '80px',
											marginLeft: '-11px',
										}}
									>
										<img
											src={
												affiliateData[0].checked
													? CheckboxIcon
													: CheckboxBlankIcon
											}
											alt=""
											width="15"
											height="15"
											style={{ borderRadius: '3px' }}
										/>
										<span
											style={{
												fontSize: '14px',
												marginLeft: '10px',
												fontFamily: 'Reem Kufi, sans-serif',
												fontWeight: 700,
												letterSpacing: '1px',
											}}
										>
											Tier 1
										</span>
									</div>
								</>
							}
							color="primary"
							id="tier1"
							onClick={handleChipBtn}
						/>

						<Chip
							sx={{
								height: '28px',
								borderRadius: '50px',
								backgroundColor: '#371E30',
							}}
							label={
								<>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											width: '80px',
											marginLeft: '-11px',
										}}
									>
										<img
											src={
												affiliateData[1].checked
													? CheckboxIcon
													: CheckboxBlankIcon
											}
											alt=""
											width="15"
											height="15"
											style={{ borderRadius: '3px' }}
										/>
										<span
											style={{
												fontSize: '14px',
												marginLeft: '10px',
												fontFamily: 'Reem Kufi, sans-serif',
												fontWeight: 700,
												letterSpacing: '1px',
											}}
										>
											Tier 2
										</span>
									</div>
								</>
							}
							color="primary"
							id="tier2"
							onClick={handleChipBtn}
						/>

						<Chip
							sx={{
								height: '28px',
								borderRadius: '50px',
							}}
							label={
								<>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											width: '120px',
											marginLeft: '-14px',
										}}
									>
										<img
											src={
												affiliateData[2].checked
													? CheckboxIcon
													: CheckboxBlankIcon
											}
											alt=""
											width="15"
											height="15"
											style={{ borderRadius: '3px' }}
										/>
										<span
											style={{
												fontSize: '14px',
												marginLeft: '8px',
												fontFamily: 'Reem Kufi, sans-serif',
												fontWeight: 700,
												letterSpacing: '1px',
											}}
										>
											Free User
										</span>
									</div>
								</>
							}
							color="primary"
							id="freeUser"
							onClick={handleChipBtn}
						/>

						<Chip
							sx={{
								height: '28px',
								borderRadius: '50px',
							}}
							label={
								<>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											width: '120px',
											marginLeft: '0px',
										}}
									>
										<img
											src={
												affiliateData[3].checked
													? CheckboxIcon
													: CheckboxBlankIcon
											}
											alt=""
											width="15"
											height="15"
											style={{ borderRadius: '3px' }}
										/>
										<span
											style={{
												fontSize: '14px',
												marginLeft: '10px',
												fontFamily: 'Reem Kufi, sans-serif',
												fontWeight: 700,
												letterSpacing: '1px',
											}}
										>
											Paid User
										</span>
										<img
											src={Businessman}
											width="18"
											height="20"
											alt="businessman"
											style={{ marginLeft: '10px' }}
										/>
									</div>
								</>
							}
							color="error"
							id="freeUser"
							onClick={handleChipBtn}
						/>

						<Chip
							sx={{
								height: '28px',
								borderRadius: '50px',
							}}
							label={
								<>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											width: '145px',
											marginLeft: '-3px',
										}}
									>
										<img
											src={
												affiliateData[4].checked
													? CheckboxIcon
													: CheckboxBlankIcon
											}
											alt=""
											width="15"
											height="15"
											style={{ borderRadius: '3px' }}
										/>
										<span
											style={{
												fontSize: '14px',
												marginLeft: '7px',
												fontFamily: 'Reem Kufi, sans-serif',
												fontWeight: 700,
												letterSpacing: '1px',
											}}
										>
											Free Affiliate
										</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 512 512"
											xmlSpace="preserve"
										>
											<path
												d="M285.213 212.865h53.154s2.903-63.743-62.294-72.879V115.9h1.038-25.33v23.465s-82.846 4.569-62.08 86.789c0 0 3.313 21.178 42.77 41.53 0 0 48.581 17.856 47.338 26.991 0 0 18.577 37.375-21.855 39.867 0 0-27.348-2.701-27.348-36.649H177.34s-3.736 68.52 70.705 75.374v22.423h25.221V374.2s55.136-1.871 63.541-55.126c0 0 13.397-50.769-58.548-78.797 0 0-39.562-12.461-39.249-34.883 0 0-1.246-21.18 13.39-24.607-.001-.001 31.674-11.833 32.813 32.078z"
												fill="#f5f5f5"
												className="fill-000000"
											/>
										</svg>
									</div>
								</>
							}
							color="success"
							onClick={handleChipBtn}
						/>

						<Chip
							sx={{
								height: '28px',
								borderRadius: '50px',
							}}
							label={
								<>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											width: '140px',
											marginLeft: '-3px',
										}}
									>
										<img
											src={
												affiliateData[5].checked
													? CheckboxIcon
													: CheckboxBlankIcon
											}
											alt=""
											width="15"
											height="15"
											style={{ borderRadius: '3px' }}
										/>
										<span
											style={{
												fontSize: '14px',
												marginLeft: '8px',
												marginRight: '1px',
												fontFamily: 'Reem Kufi, sans-serif',
												fontWeight: 700,
												letterSpacing: '1px',
											}}
										>
											Paid Affiliate
										</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 1792 1792"
											height="20"
										>
											<path
												d="M522 883q-74-162-74-371H192v96q0 78 94.5 162T522 883zm1078-275v-96h-256q0 209-74 371 141-29 235.5-113t94.5-162zm128-128v128q0 71-41.5 143t-112 130-173 97.5T1186 1023q-42 54-95 95-38 34-52.5 72.5T1024 1280q0 54 30.5 91t97.5 37q75 0 133.5 45.5T1344 1568v64q0 14-9 23t-23 9H480q-14 0-23-9t-9-23v-64q0-69 58.5-114.5T640 1408q67 0 97.5-37t30.5-91q0-51-14.5-89.5T701 1118q-53-41-95-95-113-5-215.5-44.5t-173-97.5-112-130T64 608V480q0-40 28-68t68-28h288v-96q0-66 47-113t113-47h576q66 0 113 47t47 113v96h288q40 0 68 28t28 68z"
												fill="#fafafa"
												className="fill-000000"
											/>
										</svg>
									</div>
								</>
							}
							color="warning"
							clickable
							onClick={handleChipBtn}
						/>
					</Stack>
				</Stack>
			</Container>
			<Container className="centralise">
				<br />
				<TextField
					style={{ width: '70%' }}
					label="Search"
					name="search"
					type="search"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>
				{selectedSet.size ? <Button onClick={() => setSelectedSet(new Set())}>Deselect Everyone</Button> : null}
			</Container>
			<Container>
				<TransformWrapper
					initialScale={1}
					initialPositionX={0}
					initialPositionY={0}
					wheel={{ disabled: true }}
				>
					{({ zoomIn, zoomOut, ...rest }) => (
						<>
							<TransformComponent>
								<Stack
									direction="column"
									sx={{
										width: '980px',
										minHeight: '100vh',
										marginLeft: '20px',
									}}
								>
									{
										thrivecartAffiliates.map(customer =>
											<AffiliatePill
												selectedSet={selectedSet}
												key={Math.random()}
												user={customer}
												tier={0}
												fromThrivecart={true}
												onClick={() => null}
												onSelected={() => null}
											/>
										)
									}
									{users.filter(searchFilter).map((user, index) =>
										<div key={index}>
											<AffiliatePill
												selectedSet={selectedSet}
												affiliateData={affiliateData}
												key={index}
												user={user}
												tier={0}
												onClick={() => findAndToggleChildren([index])}
												onSelected={() => onSelectUser([index])}
											/>
											{!user.showChildren ? null : (
												<>
													{user.subusers.filter(searchFilter).map((subuser, subIndex) => (
														<>
															<AffiliatePill
																selectedSet={selectedSet}
																affiliateData={affiliateData}
																key={subIndex}
																user={subuser}
																tier={1}
																onClick={() => findAndToggleChildren([index, subIndex])}
																onSelected={() => onSelectUser([index, subIndex])}
															/>
															{!subuser.showChildren ? null : (
																<>
																	{subuser.subusers
																		.filter(searchFilter)
																		.map((tier2user, tierIndex) => (
																			<>
																				<AffiliatePill
																					selectedSet={selectedSet}
																					affiliateData={affiliateData}
																					key={tierIndex}
																					user={tier2user}
																					tier={2}
																					onClick={() =>
																						findAndToggleChildren([
																							index,
																							subIndex,
																							tierIndex,
																						])
																					}
																					onSelected={() => onSelectUser([
																						index,
																						subIndex,
																						tierIndex,
																					])}
																				/>
																			</>
																		))}
																</>
															)}
														</>
													))}
												</>
											)}
										</div>
									)}
								</Stack>
							</TransformComponent>
							<Box
								sx={{
									position: 'fixed',
									bottom: '0',
									right: '0',
									marginBottom: '10px',
									marginRight: '10px',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}
							>
								<h5 style={{ marginLeft: '30px' }}>
									You have{' '}
									{currentApp === 'all' ? (
										<strong>{allUsers.length} user(s)</strong>
									) : (
										<strong>{users.length} user(s)</strong>
									)}
								</h5>
								<ButtonGroup orientation="horizontal">
									<Button variant="contained" onClick={() => zoomIn()}>
										<BsPlusLg />
									</Button>
									<Button variant="contained" onClick={() => zoomOut()}>
										<BiMinus />
									</Button>
									<Button
										variant="contained"
										color="error"
										onClick={handleOpen}
									>
										<AiOutlineMail />
									</Button>
								</ButtonGroup>
							</Box>
						</>
					)}
				</TransformWrapper>
				{selectedPeople ? // selected people
					<Modal size="lg" show={selectPopup} onHide={() => setSelectPopup(null)} aria-labelledby="modal-title">
						<Modal.Header closeButton>
							<Modal.Title id="modal-title">Send Message</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ width: "100%" }}>
							<FormControlLabel
								control={<Checkbox variant="outlined" checked={isNotification} onChange={e => setIsNotification(e.target.checked)} />}
								label="Notifications" />

							<TextField size="small" disabled={!isNotification} value={title} label="Notification Title" onChange={e => setTitle(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
							<TextField multiple disabled={!isNotification} value={message} label="Write Your Notification Message Here..." onChange={e => setMessage(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
							<TextField size="small" disabled={!isNotification} value={url} label="URL when notification is clicked" onChange={e => setUrl(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
							<TextField size="small" disabled={!isNotification} value={image} label="Notification Image URL" onChange={e => setImage(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
							<br />

							<h5>Email</h5>
							<h6><i>To Send Emails update your SMTP Settings <Link to="/settings-email">here.</Link></i></h6>
							<FormControlLabel
								control={<Checkbox color="success" variant="outlined" checked={isEmail} onChange={e => setIsEmail(e.target.checked)} />}
								label="Email" />

							<hr />
							<TextField disabled={!isEmail} size="small" value={emailTitle} label="Email Title" onChange={e => setEmailTitle(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
							<TextField disabled={!isEmail} size="small" value={emailSubject} label="Email Subject" onChange={e => setEmailSubject(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
							<TextField disabled={!isEmail} multiple value={emailHTML} label="Write Your Email Message Here..." onChange={e => setEmailHTML(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />

							{/* <h6 style={{ color: "grey", margin: 10 }}>Buttons</h6>
                    <Row xs={2}>
                        <Col>
                            <TextField size="small" value={button1} label="Button 1" onChange={e => setButton1(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                        </Col>
                        <Col>
                            <TextField size="small" value={buttonURL1} label="Button 1 Link" onChange={e => setButtonURL1(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                        </Col>
                    </Row>
                    <Row xs={2}>
                        <Col>
                            <TextField size="small" value={button2} label="Button 2" onChange={e => setButton2(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                        </Col>
                        <Col>
                            <TextField size="small" value={buttonURL2} label="Button 2 Link" onChange={e => setButtonURL2(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                        </Col>
                    </Row> */}

						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" disabled={loadingMessage} onClick={() => setSelectPopup(null)}>CANCEL</Button>
							<Button variant="primary" disabled={loadingMessage} onClick={onSendNotification}>{loading ? "LOADING..." : "SEND"}</Button>
						</Modal.Footer>
					</Modal>
					: <PopupComponet users={allUsers} open={open} handleClose={handleClose} />
				}
			</Container>
		</>
	);
}

export default ContentAffiliateNetwork
