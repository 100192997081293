import React, { useContext } from 'react'
import SideBar from '../components/SideBar'
import { Alert, Button, Col, Container, Row } from 'react-bootstrap'
import { AnimationLoading } from '../components/Lottie';
import Detail from "../components/Detail";
import { ContextApps, ContextRegisteredApps } from '../App';
import swal from 'sweetalert';



function PageApps() {

  const apps = useContext(ContextApps);
  const registeredApps = useContext(ContextRegisteredApps);


  function isRegistered(app) {
    const name = app.name.replace(/\s/gmi, '').toLowerCase();
    const registered_app = registeredApps[name];
    return registered_app && registered_app.result
  }

  function onShare(app) {
    const name = app.name.replace(/\s/gmi, '').toLowerCase();
    const registered_app = registeredApps[name];
    navigator.clipboard.writeText(registered_app.share_url).then(() => {
      swal(app.name, 'Affiliate Link Copied To Clipboard', 'success');
    })
  }

  if (!apps || apps.length === 0) {
    return (
      <Container>
        <AnimationLoading title="Loading Apps..." />
      </Container>
    )
  }

  return (
    <SideBar>
      <Container className="centralise">

        <Alert.Heading>
          <h1>
            <img width={24} height={24} src="https://img.icons8.com/fluency/48/000000/apps-tab.png" alt="app" />
            APPS
          </h1>
        </Alert.Heading>

        <br />
        <Row xs={1} sm={2} md={3} lg={4} lx={6}>
          {
            (apps.map ? apps : []).map(app =>
              <Col key={app._id} className="centralise">
                <Alert variant="light" style={{ height: 600 }} className="dropShadow">
                  <img height={200} src={app.image} width={200} alt={app.name} />
                  <Alert.Heading><h3>{app.name}</h3></Alert.Heading>
                  <Alert.Heading><h4><i><Detail text={app.description} max={150} /></i></h4></Alert.Heading>
                  <hr />
                  <Button variant="primary" onClick={() => window.open(app.chrome_webstore_url, "_blank")} className="round hover dropShadow">
                    <img width={24} height={24} alt="chrome" src="https://img.icons8.com/color/24/000000/chrome--v1.png" />
                    <strong>Download</strong>
                  </Button>
                  <br /> <br />
                  {isRegistered(app) ?
                    <Button variant="info" onClick={() => onShare(app)} className="round hover dropShadow">
                      <img width={24} height={24} alt="chrome" src="https://img.icons8.com/fluency/24/000000/share--v2.png" />
                      <strong>Affiliate Link</strong>
                    </Button> : null
                  }
                  <br /><br />
                  <Button variant="danger" disabled={true} className="round hover dropShadow">
                    <img width={24} height={24} alt="chrome" src="https://img.icons8.com/external-flat-geotatah/24/000000/external-register-training-management-system-flat-flat-geotatah.png" />
                    <strong>Register As Affiliate</strong>
                  </Button>
                </Alert>
              </Col>
            )
          }
        </Row>
      </Container>
    </SideBar>
  )
}

export default PageApps