import React, { useContext, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Modal,
	Paper,
	Stack,
	TextareaAutosize,
	TextField,
} from '@mui/material';
import { ContextApps } from '../App';
import * as API from "../utils/api";
import { ToastContainer } from 'react-toastify';
import { errorToast } from '../utils/toaster';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';

import { Col, Row, Container } from 'react-bootstrap';
import BootstrapModal from "react-bootstrap/Modal";
import { AnimationLoading } from "./Lottie";



const CheckboxBlankIcon = 'images/checkbox_fill.png';
const CheckboxIcon = 'images/checkbox_green.png';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	pt: 2,
	px: 2,
	pb: 3,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
};

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

const PopupComponet = ({ open, handleClose, mobile }) => {


	const apps = useContext(ContextApps);
	const [messageApps, setMessageApps] = useState([])
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState('');
	const [url, setUrl] = useState('');
	const [checked, setChecked] = useState([]);
	const [left, setLeft] = useState(apps.map((app) => app.name));
	const [right, setRight] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [affiliateData, setAffiliateData] = useState({
		title: '',
		titleError: '',
		message: '',
		messageError: '',
		url: '',
		tier1: false,
		tier2: false,
		freeUser: false,
		paidUser: false,
		freeAffiliate: false,
		paidAffiliate: false,
	});

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		setRight(right.concat(left));
		setLeft([]);
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		setLeft(left.concat(right));
		setRight([]);
	};


	const handleChange = (e) => {
		setAffiliateData({
			...affiliateData,
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
		});
	};


	const handleChipBtn = (e) => {
		console.log(typeof e.target.innerText);
		if (e.target.innerText === 'T1')
			setAffiliateData({ ...affiliateData, tier1: !affiliateData.tier1 });
		if (e.target.innerText === 'T2')
			setAffiliateData({ ...affiliateData, tier2: !affiliateData.tier2 });
		if (e.target.innerText === 'Free User')
			setAffiliateData({ ...affiliateData, freeUser: !affiliateData.freeUser });
		if (e.target.innerText === 'Paid User')
			setAffiliateData({ ...affiliateData, paidUser: !affiliateData.paidUser });
		if (e.target.innerText === 'Free Affiliate')
			setAffiliateData({
				...affiliateData,
				freeAffiliate: !affiliateData.freeAffiliate,
			});
		if (e.target.innerText === 'Paid Affiliate')
			setAffiliateData({
				...affiliateData,
				paidAffiliate: !affiliateData.paidAffiliate,
			});
	};

	const handleSend = async () => {
		const chosenApps = [...right];

		const {
			tier1,
			tier2,
			title,
			message,
			url,
			freeUser,
			paidUser,
			freeAffiliate,
			paidAffiliate,
		} = affiliateData;

		if (apps.length < 1) return errorToast('select at least one application');
		if (title === '')
			return setAffiliateData({
				...affiliateData,
				titleError: 'Title is required',
			});
		setAffiliateData({ ...affiliateData, titleError: '' });
		if (message === '' || message === undefined)
			return errorToast('Message is required');
		if (
			!tier1 &&
			!tier2 &&
			!freeUser &&
			!paidUser &&
			!freeAffiliate &&
			!paidAffiliate
		)
			return errorToast('Select at least one affiliate plan');

		const selectedApps = apps.filter((app) => chosenApps.indexOf(app.name) !== -1);

		try {

			setLoading(true);

			const {
				tier1,
				tier2,
				title,
				message,
				url,
				freeUser,
				paidUser,
				freeAffiliate,
				paidAffiliate,
			} = affiliateData;

			const data = {
				tier1,
				tier2,
				apps: selectedApps,
				title: title,
				url: url,
				message: message,
				free_users: freeUser,
				paid_users: paidUser,
				free_affiliates: freeAffiliate,
				paid_affiliates: paidAffiliate,
			};

			const res = await API.PostAPI('/api/notifications/send/message', data);

			if (!res.result) return swal('Oh No', 'Message could not be sent. Check your internet', 'info');


			handleClose();
			swal('Message Sent', 'Your message has been sent successfully', 'success');
			setAffiliateData({
				title: '',
				titleError: '',
				message: '',
				messageError: '',
				url: '',
				freeUser: false,
				paidUser: false,
				freeAffiliate: false,
				paidAffiliate: false,
			});
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const mobileSendMessage = async () => {
		try {

			const {
				tier1,
				tier2,
				freeUser,
				paidUser,
				freeAffiliate,
				paidAffiliate,
			} = affiliateData;

			const data = {
				tier1,
				tier2,
				apps: messageApps,
				title: title,
				url: url,
				message: message,
				free_users: freeUser,
				paid_users: paidUser,
				free_affiliates: freeAffiliate,
				paid_affiliates: paidAffiliate,
			};

			const res = await API.PostAPI('/api/notifications/send/message', data);

			if (!res.result) return swal('Oh No', 'Message could not be sent. Check your internet', 'info');


			handleClose();
			swal('Message Sent', 'Your message has been sent successfully', 'success');
			setAffiliateData({
				title: '',
				titleError: '',
				message: '',
				messageError: '',
				url: '',
				freeUser: false,
				paidUser: false,
				freeAffiliate: false,
				paidAffiliate: false,
			});
		} catch (e) {
			console.log(e.message);
		} finally {
			setLoading(false);
		}
	}

	const onToggleMessageApp = (app) => {
		if (messageApps.find(a => a === app) !== undefined) {
			const index = messageApps.findIndex(a => a === app);
			const list = [];
			messageApps.forEach((a, i) => {
				if (i !== index) list.push(a);
			})
			setMessageApps(list);
		} else {
			messageApps.push(app);
			setMessageApps([...messageApps]);
		}
	}

	const customList = (items) => (
		<Paper
			sx={{
				width: 230,
				height: 230,
				overflow: 'auto',
			}}
		>
			<List dense component="div" role="list">
				{items.map((value) => {
					const labelId = `transfer-list-item-${value}-label`;

					return (
						<ListItem
							key={value}
							role="listitem"
							button
							onClick={handleToggle(value)}
							sx={{
								background: 'whiteSmoke',
								borderRadius: '30px',
								border: '1px solid #d5d5d5',
								marginBottom: '10px',
								height: '30px',
							}}
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={value} />
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Paper>
	);

	if (loading) {
		return (
			<Container className='centralise'>
				<AnimationLoading width={200} title="Sending Message" />
			</Container>
		);
	}

	if (mobile) {
		return (
			<BootstrapModal style={{ width: "97vw" }} show={open} onHide={handleClose}>
				<BootstrapModal.Header>Messaging</BootstrapModal.Header>
				<BootstrapModal.Body>

					<div className="centralise">
						<TextField style={{ width: "80%" }} label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
						<br /><br />
						<TextField style={{ width: "80%" }} label="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
						<br /><br />
						<TextField style={{ width: "80%" }} label="URL" type="url" value={url} onChange={(e) => setUrl(e.target.value)} />
						<br /><br />
						<Button variant="contained" onClick={mobileSendMessage}>
							Send Message
						</Button>

					</div>

					<br />

					<Row xs={2} className="centralise">
						<Col>
							<Chip
								sx={{ height: '28px', backgroundColor: '#FF557F' }}
								label={
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												width: '50px',
												marginLeft: '-10px',
											}}
										>
											<img
												src={
													affiliateData.tier1
														? CheckboxIcon
														: CheckboxBlankIcon
												}
												alt=""
												srcset=""
												width="15"
												height="15"
												style={{ borderRadius: '3px' }}
											/>
											<span
												style={{ fontSize: '14px', marginLeft: '10px' }}
											>
												T1
											</span>
										</div>
									</>
								}
								color="primary"
								onClick={handleChipBtn}
							/>
						</Col>
						<Col>
							<Chip
								sx={{ height: '28px' }}
								label={
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												width: '50px',
												marginLeft: '-10px',
											}}
										>
											<img
												src={
													affiliateData.tier2
														? CheckboxIcon
														: CheckboxBlankIcon
												}
												alt=""
												srcset=""
												width="15"
												height="15"
												style={{ borderRadius: '3px' }}
											/>
											<span
												style={{ fontSize: '14px', marginLeft: '10px' }}
											>
												T2
											</span>
										</div>
									</>
								}
								color="secondary"
								onClick={handleChipBtn}
							/>
						</Col>
					</Row>
					<Row xs={2} className="centralise">
						<Col>
							<Chip
								sx={{ height: '28px' }}
								label={
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												width: '120px',
												marginLeft: '-14px',
											}}
										>
											<img
												src={
													affiliateData.freeUser
														? CheckboxIcon
														: CheckboxBlankIcon
												}
												alt=""
												srcset=""
												width="20"
												height="20"
												style={{ borderRadius: '3px' }}
											/>
											<span
												style={{ fontSize: '14px', marginLeft: '10px' }}
											>
												Free User
											</span>
										</div>
									</>
								}
								color="primary"
								id="freeUser"
								onClick={handleChipBtn}
							/>
						</Col>
						<Col>
							<Chip
								sx={{ height: '28px' }}
								label={
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												width: '100px',
											}}
										>
											<img
												src={
													affiliateData.paidAffiliate
														? CheckboxIcon
														: CheckboxBlankIcon
												}
												alt=""
												srcset=""
												width="20"
												height="20"
												style={{ borderRadius: '3px' }}
											/>
											<span
												style={{ fontSize: '14px', marginLeft: '10px' }}
											>
												Paid Affiliate
											</span>
										</div>
									</>
								}
								color="warning"
								id="freeUser"
								onClick={handleChipBtn}
							/>
						</Col>
						<Col>
							<Chip
								sx={{ height: '28px' }}
								label={
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												width: '100px',
											}}
										>
											<img
												src={
													affiliateData.freeAffiliate
														? CheckboxIcon
														: CheckboxBlankIcon
												}
												alt=""
												srcset=""
												width="20"
												height="20"
												style={{ borderRadius: '3px' }}
											/>
											<span
												style={{ fontSize: '14px', marginLeft: '10px' }}
											>
												Free Affiliate
											</span>
										</div>
									</>
								}
								color="success"
								id="freeUser"
								onClick={handleChipBtn}
							/>
						</Col>
						<Col>
							<Chip
								sx={{ height: '28px' }}
								label={
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												width: '110px',
												marginLeft: '-14px',
											}}
										>
											<img
												src={
													affiliateData.paidUser
														? CheckboxIcon
														: CheckboxBlankIcon
												}
												alt=""
												srcset=""
												width="20"
												height="20"
												style={{ borderRadius: '3px' }}
											/>
											<span
												style={{ fontSize: '14px', marginLeft: '10px' }}
											>
												Paid User
											</span>
										</div>
									</>
								}
								color="error"
								onClick={handleChipBtn}
							/>
						</Col>
					</Row>

					<br />

					<Box sx={{ width: "100%" }}>


						<Row xs={1}>
							{
								apps.map(app =>
									<Col key={app._id}>
										<FormControlLabel
											control={
												<Checkbox checked={messageApps.find(a => a === app) !== undefined} onChange={(e) => onToggleMessageApp(app)} name={app.name} />
											}
											label={app.name}
										/>
									</Col>
								)
							}

						</Row>

					</Box>
				</BootstrapModal.Body>
			</BootstrapModal>
		)
	}

	return (
		<div className="">
			<Modal open={open} onClose={handleClose}>
				<form action="">
					<ToastContainer />
					<Box
						sx={{
							...style,
							width: 700,
							height: 600,
						}}
					>
						<Box
							sx={{
								height: '100%',
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								borderBottom: '1px solid #d5d5d5',
							}}
						>
							<Box
								sx={{
									background: 'white',
									width: '65%',
									padding: '10px',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<Stack direction="row" justifyContent="space-between">
									<Box sx={{ textAlign: 'center' }}>
										<h6>Apps</h6>
										<Grid>
											<Grid item>
												{customList(
													left.filter(
														(item) =>
															item
																.toLowerCase()
																.indexOf(searchQuery.toLowerCase()) > -1
													)
												)}
											</Grid>
										</Grid>
									</Box>
									<Stack spacing={2}>
										<TextField
											sx={{ width: '20ch', marginTop: '25px' }}
											label="Search"
											onChange={(e) => setSearchQuery(e.target.value)}
										/>
										<Grid>
											<Divider>
												<Grid item>
													<Grid
														container
														direction="column"
														alignItems="center"
													>
														<Button
															sx={{ my: 0.5 }}
															variant="outlined"
															size="small"
															onClick={handleAllRight}
															disabled={left.length === 0}
															aria-label="move all right"
														>
															≫
														</Button>
														<Button
															sx={{ my: 0.5 }}
															variant="outlined"
															size="small"
															onClick={handleCheckedRight}
															disabled={leftChecked.length === 0}
															aria-label="move selected right"
														>
															&gt;
														</Button>
														<Button
															sx={{ my: 0.5 }}
															variant="outlined"
															size="small"
															onClick={handleCheckedLeft}
															disabled={rightChecked.length === 0}
															aria-label="move selected left"
														>
															&lt;
														</Button>
														<Button
															sx={{ my: 0.5 }}
															variant="outlined"
															size="small"
															onClick={handleAllLeft}
															disabled={right.length === 0}
															aria-label="move all left"
														>
															≪
														</Button>
													</Grid>
												</Grid>
											</Divider>
										</Grid>
									</Stack>
								</Stack>
								<Stack spacing={2} sx={{ marginTop: '20px' }}>
									<TextField
										required
										label="Title"
										name="title"
										onChange={handleChange}
										error={affiliateData.titleError !== '' ? true : false}
										helperText={
											affiliateData.titleError !== ''
												? affiliateData.titleError
												: ''
										}
									/>
									<TextareaAutosize
										minRows={3}
										placeholder="Message"
										name="message"
										onChange={handleChange}
									/>
									<TextField
										label="Notification Click Url"
										name="url"
										onChange={handleChange}
									/>
								</Stack>
							</Box>
							<Divider sx={{ width: '1px', background: '#d5d5d5' }} />
							<Stack
								alignItems="center"
								justifyContent="center"
								sx={{
									background: 'white',
									width: '35%',
									display: 'flex',
								}}>
								<h6>Selected Apps</h6>
								<Grid>
									<Grid item>{customList(right)}</Grid>
								</Grid>
								<Stack
									alignItems="center"
									justifyContent="center"
									spacing={1}
									sx={{
										marginLeft: '10px',
										marginTop: '5px',
										marginBottom: '10px',
									}}>
									<h6>Affiliate Plan</h6>
									<Stack direction="row" spacing={2}>
										<Chip
											sx={{ height: '28px', backgroundColor: '#FF557F' }}
											label={
												<>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
															justifyContent: 'center',
															width: '50px',
															marginLeft: '-10px',
														}}
													>
														<img
															src={
																affiliateData.tier1
																	? CheckboxIcon
																	: CheckboxBlankIcon
															}
															alt=""
															srcset=""
															width="15"
															height="15"
															style={{ borderRadius: '3px' }}
														/>
														<span
															style={{ fontSize: '14px', marginLeft: '10px' }}
														>
															T1
														</span>
													</div>
												</>
											}
											color="primary"
											onClick={handleChipBtn}
										/>
										<Chip
											sx={{ height: '28px' }}
											label={
												<>
													<div
														style={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
															justifyContent: 'center',
															width: '50px',
															marginLeft: '-10px',
														}}
													>
														<img
															src={
																affiliateData.tier2
																	? CheckboxIcon
																	: CheckboxBlankIcon
															}
															alt=""
															srcset=""
															width="15"
															height="15"
															style={{ borderRadius: '3px' }}
														/>
														<span
															style={{ fontSize: '14px', marginLeft: '10px' }}
														>
															T2
														</span>
													</div>
												</>
											}
											color="secondary"
											onClick={handleChipBtn}
										/>
									</Stack>
									<Chip
										sx={{ height: '28px' }}
										label={
											<>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'center',
														width: '140px',
														marginLeft: '-14px',
													}}
												>
													<img
														src={
															affiliateData.freeUser
																? CheckboxIcon
																: CheckboxBlankIcon
														}
														alt=""
														srcset=""
														width="20"
														height="20"
														style={{ borderRadius: '3px' }}
													/>
													<span
														style={{ fontSize: '14px', marginLeft: '10px' }}
													>
														Free User
													</span>
												</div>
											</>
										}
										color="primary"
										id="freeUser"
										onClick={handleChipBtn}
									/>
									<Chip
										sx={{ height: '28px' }}
										label={
											<>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'center',
														width: '140px',
														marginLeft: '-14px',
													}}
												>
													<img
														src={
															affiliateData.paidUser
																? CheckboxIcon
																: CheckboxBlankIcon
														}
														alt=""
														srcset=""
														width="20"
														height="20"
														style={{ borderRadius: '3px' }}
													/>
													<span
														style={{ fontSize: '14px', marginLeft: '10px' }}
													>
														Paid User
													</span>
												</div>
											</>
										}
										color="error"
										onClick={handleChipBtn}
									/>
									<Chip
										sx={{ height: '28px' }}
										label={
											<>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'center',
														width: '120px',
													}}
												>
													<img
														src={
															affiliateData.freeAffiliate
																? CheckboxIcon
																: CheckboxBlankIcon
														}
														alt=""
														srcset=""
														width="20"
														height="20"
														style={{ borderRadius: '3px' }}
													/>
													<span
														style={{ fontSize: '14px', marginLeft: '10px' }}
													>
														Free Affiliate
													</span>
												</div>
											</>
										}
										color="success"
										id="freeUser"
										onClick={handleChipBtn}
									/>
									<Chip
										sx={{ height: '28px' }}
										label={
											<>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'center',
														width: '120px',
													}}
												>
													<img
														src={
															affiliateData.paidAffiliate
																? CheckboxIcon
																: CheckboxBlankIcon
														}
														alt=""
														srcset=""
														width="20"
														height="20"
														style={{ borderRadius: '3px' }}
													/>
													<span
														style={{ fontSize: '14px', marginLeft: '10px' }}
													>
														Paid Affiliate
													</span>
												</div>
											</>
										}
										color="warning"
										id="freeUser"
										onClick={handleChipBtn}
									/>
								</Stack>
							</Stack>
						</Box>
						<Box sx={{ display: 'flex', marginTop: '10px' }}>
							<Button variant="contained" onClick={handleSend}>
								Send
							</Button>
						</Box>
					</Box>
				</form>
			</Modal>
		</div>
	);
};

export default PopupComponet;
