

import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import Cookies from 'js-cookie';
//http://localhost:3000/oauth?appToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJvcy5jb2FjaGluZzM2MEBnbWFpbC5jb20iLCJ1aWQiOiI2MmQ3ZWRkYTQ4NGU3ZDFhODRmZDRhYjgiLCJpYXQiOjE3MDYwMjcxMTF9.P0nTVqfzujbhi_R552sZJRWbtS2UCRqBbdO15dyJtYE&appName=FBScraper

const logo = "/images/logo.png";
 

function PageOAuth() {

    const [searchParams, setSearchParams] = useSearchParams();
    const router = useNavigate();
    const appToken = searchParams.get('appToken');
    const appName = searchParams.get('appName');
    
    // Login via the extension
    useEffect(() => {
        const run = async () => {
            try {
               
                if (appToken) {
                    const { access_token } = await API.PostAPI('/api/apps/convert/token', { access_token: appToken }, false);
                    Cookies.set(API.REACT_APP_COOKIE_KEY, access_token);
                    router('/');
                } else {
                    console.log('No App Token');
                }
            } catch (e) {
                console.log(e.message);
                router('/');
            }
        }
        run();
    }, [appToken, appName, router])


    return (
        <Container style={{ paddingTop: 140, paddingBottom: 40 }} className="centralise" fluid>
            <img src={logo} alt="logo" width={200} height={200} />
            <h1>BOS Applications Dashboard</h1>
            <AnimationLoading width={200} title="Loading" />
        </Container>
    )
}

export default PageOAuth
