import React, { useEffect, useState } from 'react'
import SideBar from '../components/SideBar'
import ContentAffiliateNetwork from '../content/ContentAffiliateNetwork'
import ContentAffiliateNetworkMobile from '../content/ContentAffiliateNetworkMobile';

function PageAffiliates() {

  const [size, setSize] = useState(1024);

  //update screen size when changed
  useEffect(() => {
      const updateSize = () => setSize(window.screen.width)
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
  }, []);


  //mobile and desktop views
  if(size <= 800) return <SideBar><ContentAffiliateNetworkMobile/></SideBar>
  return <SideBar><ContentAffiliateNetwork/></SideBar>


}

export default PageAffiliates