import { useContext, useEffect, useState } from 'react'
import { ContextSetUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import { FcGoogle } from 'react-icons/fc';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';


const logo = "/images/logo.png";


function PageLogin() {

  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const setUser = useContext(ContextSetUser);

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);
        const res = await API.GetAPI('/api/user');
        const user = res.user;

        if (user) {
          setUser(user);
          setLoading(false);
          navigation('/apps');
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
    if (API.isLoggedIn()) run();
  }, [navigation, setUser]);



  // const onFacebook = () => window.location.href = `${API.REACT_APP_BACKEND}/api/facebook/oauth`;


  const onGoogle = () => window.location.href = `${API.REACT_APP_BACKEND}/api/google/oauth`;

  const onEmail = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const user = {
        email: e.target.email.value,
        password: e.target.password.value,
      }

      const res = await API.PostAPI('/api/email/oauth', user);
      if (res.result) {
        const access_token = res.access_token;
        try {
          Cookies.set(API.REACT_APP_COOKIE_KEY, access_token);
          const response = await API.GetAPI('/api/user');
          if (response) {
            const { user } = response;
            setUser(user);
            navigation('/apps');
          }
        } catch (e) {
          console.log(e);
          navigation('/');
        }
      } else {
        console.log(res);
        swal('Sign In', 'Something happened. Check your internet connection', 'info');
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }



  return (
    <Container style={{ paddingTop: 140, paddingBottom: 40 }} className="centralise" fluid>

      <img src={logo} alt="logo" width={200} height={200} />
      <h1>BOS Dashboard</h1>
      {loading || API.isLoggedIn() ?

        <div className="app"><AnimationLoading width={250} title="Loading" /></div>

        :

        <div>
          <br />
          <Form onSubmit={onEmail}>
            <TextField style={{ width: 400 }} label="Your Email" type="email" required name="email" aria-label="email" />
            <br /> <br />
            <TextField style={{ width: 400 }} label="Your Password" type="password" required name="password" aria-label="password" />
            <br /><br />
            <div style={{ width: 400 }} className="centralise">
              <Row xs={2}  >
                <Col>
                  <h6>No <Link to="/signup">Account?</Link></h6>
                </Col>
                <Col>
                  <h6>Forgot <Link to="/password">Password?</Link></h6>
                </Col>
              </Row>
            </div>
            <br />
            <Button style={{ width: 400 }} disabled={loading} type="submit" variant="light" size="lg" className="hover dropShadow">
              <strong>Login With Email</strong>
            </Button>
          </Form>
          <br />
          <Button disabled={loading} style={{ width: 400 }} size="lg" onClick={onGoogle} variant="light" className="hover dropShadow">
            <FcGoogle size={24} />
            {" "}
            <strong>{loading ? "Loading..." : "Continue With Google"}</strong>
          </Button>
        </div>
      }
      <br /><br /><br /><br />
    </Container>
  )
}

export default PageLogin
