/* eslint-disable no-undef */
/**
 * Author: Martin Kululanga
 * Github: https://github.com/m2kdevelopments
*/

// Documentation - https://apidocs.thrivecart.com/

import * as API from "./api";
const url = "https://admin.mysocial360.com/thrivecart";

export async function getProducts() {
    try {
        const response = await API.Get(`${url}/api/thrive/listProducts`)
        return response;
    } catch (e) {
        console.log(e);
        return [];
    }
}


export async function getCustomers(count = 2000, page = 0) {
    try {
        const response = await API.Get(`${url}/api/customers/all?count=${count}&page=${page}`)
        return response;
    } catch (e) {
        console.log(e);
        return [];
    }
}


export async function getAllAffiliates(email) {
    try {
        const response = await API.Post(`${url}/api/customers/get/referrals`, { email })
        return response;
    } catch (e) {
        console.log(e);
        return [];
    }
}


export async function getWhiteList() {
    try {
        const response = await API.Get(`${url}/api/whitelist`)
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}

export async function addWhiteListedCustomers(customers, app_id, package_id) {
    try {
        const response = await API.Post(`${url}/api/whitelist/customers`, { customers, app_id, package_id })
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}


export async function addCustomersViaCSV(customers) {
    try {
        const response = await API.Post(`${url}/api/customers/csv`, { customers })
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}


export async function addWhiteList(whitelistMember) {
    try {
        const response = await API.Post(`${url}/api/whitelist`, whitelistMember)
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}

export async function editWhiteList(id, whitelistMember) {
    try {
        const response = await API.Patch(`${url}/api/whitelist/${id}`, whitelistMember)
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}

export async function removeWhiteList(id) {
    try {
        const response = await API.Delete(`${url}/api/whitelist/${id}`)
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}



export async function getBacklistList() {
    try {
        const response = await API.Get(`${url}/api/blacklist`)
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}

export async function addBlackList(member) {
    try {
        const response = await API.Post(`${url}/api/blacklist`, member)
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}


export async function removeBlackList(id) {
    try {
        const response = await API.Delete(`${url}/api/blacklist/${id}`)
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}


export async function updateCustomerPackages(id, packages) {
    try {
        const response = await API.Patch(`${url}/api/customers/${id}`, { packages })
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}

export async function deleteCustomer(customer) {
    try {
        const response = await API.Delete(`${url}/api/customers/${customer.id}`)
        return response;
    } catch (e) {
        console.log(e);
        return e.response.data;
    }
}