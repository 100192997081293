import React from 'react'
import SideBar from '../components/SideBar'
import ContentNotifications from '../content/ContentNotifications'

function PageNotifications() {
  return (
    <SideBar><ContentNotifications/></SideBar>
  )
}

export default PageNotifications