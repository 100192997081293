//https://getstream.io/chat/react-chat/tutorial/

import React from 'react';
import { AnimationComingSoon } from '../components/Lottie';

// const chatClient = StreamChat.getInstance('dz5f4d5kzrue');
// const userToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiZmFsbGluZy1yYWluLTIiLCJleHAiOjE2NDM3Njg0Mjl9.nTnqNbDsFC0wBobeMvOguiomZ83uBi8hOD_28PTbXKc';

// chatClient.connectUser(
//   {
//     id: 'falling-rain-2',
//     name: 'falling',
//     image: 'https://getstream.io/random_png/?id=falling-rain-2&name=falling',
//   },
//   userToken,
// );

// const channel = chatClient.channel('messaging', 'custom_channel_id', {
//   // add as many custom fields as you'd like
//   image: 'https://www.drupal.org/files/project-images/react.png',
//   name: 'Talk about React',
//   members: ['falling-rain-2'],
// });

function ContentNotifications() {
    return (<AnimationComingSoon/>
        //  <Chat client={chatClient} theme='messaging light'>
        //     <Channel channel={channel}>
        //     <Window>
        //         <ChannelHeader />
        //         <MessageList />
        //         <MessageInput />
        //     </Window>
        //     <Thread />
        //     </Channel>
        // </Chat>
    )
}

export default ContentNotifications
