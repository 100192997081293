import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import { initializeApp } from "firebase/app"; 


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAT1Ow8U-jAkq8GDp9LGIulUo4VpXyoguQ",
  authDomain: "bosapplications.firebaseapp.com",
  projectId: "bosapplications",
  storageBucket: "bosapplications.appspot.com",
  messagingSenderId: "883983125264",
  appId: "1:883983125264:web:a33881914d8d4c169bf395",
  measurementId: "G-LKPZJBL1K8"
};


// Initialize Firebase
initializeApp(firebaseConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); 