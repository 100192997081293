import axios from "axios";
import * as API from './api'
const backend_url = API.REACT_APP_BACKEND;
const headers = {
    'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
    'Content-Type': 'application/json; charset=utf-8',
    //'Access-Control-Allow-Origin': '*',
    //'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    //'Access-Control-Allow-Methods': 'PUT, POST, PATCH, DELETE, GET'
};

const instance = axios.create();

export async function getReferrals(email) {

    try {
        headers["email"] = email;
        const response = await instance.get(backend_url + '/api/referrals', { headers });
        return response.data;
    } catch (e) {
        console.log(e);
        return [];
    }

}

export async function getRegisteredApps(email) {

    try {
        headers["email"] = email;
        const response = await instance.get(backend_url + '/api/referrals/registered/apps', { headers });
        return response.data;
    } catch (e) {
        console.log(e);
        return [];
    }
}