import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap';
import { AnimationLoading } from '../components/Lottie';
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as API from '../utils/api'
const logo = "/images/logo.png";


function PageOAuth2() {

    const router = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const appToken = searchParams.get('appToken');

    useEffect(() => {
        if (appToken) Cookies.set(API.REACT_APP_COOKIE_KEY, appToken);
        router('/');
    }, [appToken, router])


    return (
        <Container style={{ paddingTop: 140, paddingBottom: 40 }} className="centralise" fluid>
            <img src={logo} alt="logo" width={200} height={200} />
            <h1>BOS Dashboard</h1>
            <h4>Logging In</h4>
            <AnimationLoading width={200} title="Loading" />
        </Container>
    )
}

export default PageOAuth2
