/* eslint-disable no-undef */
import axios from "axios";
import Cookies from 'js-cookie';


const HEADERS = {
    'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
    'Content-Type': 'application/json; charset=utf-8',
    //'Access-Control-Allow-Origin': '*',
    //'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    //'Access-Control-Allow-Methods': 'PUT, POST, PATCH, DELETE, GET'
};

const instance = axios.create({ HEADERS });
export const REACT_APP_BACKEND = "https://beta.dashboard.mysocial360.com/dashboard"; //Get Urls for APIs
export const REACT_APP_COOKIE_KEY = "bosapplications";

const authUrl = REACT_APP_BACKEND;

export const GetAPI = (route, auth = true) => Get(`${authUrl}${route}`, auth);
export const PostAPI = (route, body = {}, auth = true) => Post(`${authUrl}${route}`, body, auth);
export const PatchAPI = (route, body = {}, auth = true) => Patch(`${authUrl}${route}`, body, auth);
export const DeleteAPI = (route, auth = true) => Delete(`${authUrl}${route}`, auth);

export function Get(url, auth = true) {
    return new Promise(async resolve => {

        if (auth) {
            const token = Cookies.get(REACT_APP_COOKIE_KEY);
            const headers = { 'Authorization': 'Bearer ' + token };
            try {
                const response = await instance.get(url, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e.response);
                if (typeof e?.response?.data == 'string') {
                    resolve({ result: false, message: e.response.data });
                } else {
                    resolve(e?.response?.data||null);
                }
            }
        } else {
            try {
                const response = await instance.get(url);
                console.log(response)
                resolve(response.data);
            } catch (e) {
                console.log({ error: e });
                if (typeof e?.response?.data == 'string') {
                    resolve({ result: false, message: e.response.data });
                } else {
                    resolve(e?.response?.data||null);
                }
            }
        }
    });
}

export function Post(url, body = {}, auth = true) {
    return new Promise(async (resolve) => {

        if (auth) {
            const token = Cookies.get(REACT_APP_COOKIE_KEY);
            const headers = { 'Authorization': 'Bearer ' + token };
            try {

                const response = await instance.post(url, body, { headers });
                return resolve(response.data);
            } catch (e) {
                console.log(e);
                if (typeof e?.response?.data == 'string') {
                    resolve({ result: false, message: e.response.data });
                } else {
                    resolve(e?.response?.data||null);
                }
            }
        } else {
            try {
                const response = await instance.post(url, body);
                return resolve(response.data);
            } catch (e) {
                console.log(e);
                if (typeof e?.response?.data == 'string') {
                    resolve({ result: false, message: e.response.data });
                } else {
                    resolve(e?.response?.data||null);
                }
            }
        }
    });
}

export function Patch(url, body = {}, auth = true) {
    return new Promise(async resolve => {

        if (auth) {
            const token = Cookies.get(REACT_APP_COOKIE_KEY);
            const ourAppId = REACT_APP_ID;

            const headers = { 'Authorization': 'Bearer ' + token, 'App': ourAppId };


            try {
                const response = await instance.patch(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                if (typeof e?.response?.data == 'string') {
                    resolve({ result: false, message: e.response.data });
                } else {
                    resolve(e?.response?.data||null);
                }
            }


        } else {
            try {
                const response = await instance.patch(url, body);
                resolve(response.data);
            } catch (e) {
                console.log(e);
                if (typeof e?.response?.data == 'string') {
                    resolve({ result: false, message: e.response.data });
                } else {
                    resolve(e?.response?.data||null);
                }
            }

        }
    });
}

export function Delete(url, auth = true) {
    return new Promise(async resolve => {

        if (auth) {
            const token = Cookies.get(REACT_APP_COOKIE_KEY);
            //refresh user if necessary

            const ourAppId = REACT_APP_ID;
            const headers = { 'Authorization': 'Bearer ' + token, 'App': ourAppId };


            try {
                const response = await instance.delete(url, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                if (typeof e?.response?.data == 'string') {
                    resolve({ result: false, message: e.response.data });
                } else {
                    resolve(e?.response?.data||null);
                }
            }


        } else {
            try {
                const response = await instance.delete(url);
                resolve(response.data);
            } catch (e) {
                console.log(e);
                if (typeof e?.response?.data == 'string') {
                    resolve({ result: false, message: e.response.data });
                } else {
                    resolve(e?.response?.data||null);
                }
            }

        }
    });
}


export function isLoggedIn() {
    return Cookies.get(REACT_APP_COOKIE_KEY) ? true : false;
}