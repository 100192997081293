import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Modal,} from 'react-bootstrap';
import {  ContextAllUsers, ContextApps, ContextRegisteredApps } from '../App';
import { useContext } from 'react'; 
import {  Button,TextField, Checkbox, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {  AiFillCopy } from 'react-icons/ai';
import { FaSearch, FaUserCircle } from 'react-icons/fa';
import { BsSendFill } from 'react-icons/bs';
import SideBar from '../components/SideBar';
import { DataGrid } from '@mui/x-data-grid';
import swal from 'sweetalert';
import * as API from '../utils/api';

export default function PageUsers() {

    const allUsers = useContext(ContextAllUsers);
    const [loading, setLoading] = useState(false);
    const registeredApps = useContext(ContextRegisteredApps);
    const apps = useContext(ContextApps);
    const [search, setSearch] = useState("");
    const [selectionModel, setSelectionModel] = useState([]);
    const [showMessenger, setShowMessenger] = useState(false);

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [url, setUrl] = useState("");

    const [titleEmail, setTitleEmail] = useState("");
    const [messageEmail, setMessageEmail] = useState("");


    const [currentApp, setCurrentApp] = useState("-1");
    const [buttonURL1, setButtonURL1] = useState("");
    const [buttonURL2, setButtonURL2] = useState("");
    const [button1, setButton1] = useState("");
    const [button2, setButton2] = useState("")

    const columns = [
        {
            field: 'number',
            headerName: '#',
            align: 'center',
            width: 60,
            renderCell: (params) => <div style={{ textAlign: "center", background: "#4b4b8b", color: "white", width: 40, height: 40, borderRadius: "50%", padding: 10 }} className="dropShadow">{params.value}</div>
        },
        {
            field: 'image',
            headerName: 'Image',
            align: 'center',
            width: 60,
            renderCell: (params) => <FaUserCircle color="grey" size={40} />
        },
        {
            field: 'name',
            headerName: 'Name',
            align: 'left',
            width: 160,
        },
        {
            field: 'email',
            headerName: 'Email',
            align: 'left',
            width: 260,
            renderCell: (params) => <div style={{ display: "flex" }}>

                <Button variant="outline-primary" size="sm" onClick={() => window.navigator.clipboard.writeText(params.value).then(() => swal('Copied Email'))} className='dropShadow hover'><AiFillCopy /></Button>
                {params.value}
            </div>
        },

        {
            field: 'total_subusers',
            headerName: 'Referrals',
            align: 'center',
            width: 80,

        },
        {
            field: 'from',
            headerName: 'App',
            align: 'left',
            width: 140,

        },
        {
            field: 'facebook',
            headerName: 'Login With Facebook',
            align: 'center',
            width: 160,
            renderCell: (params) => <Checkbox checked={params.value} disabled={true} />
        },
        {
            field: 'google',
            headerName: 'Login With Google',
            align: 'center',
            width: 160,
            renderCell: (params) => <Checkbox checked={params.value} disabled={true} />
        }
    ];


    const onSendNotification = async () => {
        try {
            if (message && title.replace(/\s/gmi) === '') return swal('Sending Message', 'Please enter a title', 'warning');
            // if (message.replace(/\s/gmi) === '') return swal('Sending Message', 'Please enter a message', 'warning');

            const people = allUsers
                .filter(user => selectionModel.indexOf(user._id) !== -1)
                .map(user => {
                    const app = apps.find(app => app.name.replace(/\s/gmi, '').toLowerCase() === user.from.replace(/\s/gmi, '').toLowerCase())
                    const url = app?.base_url || `https://app.mysocial360.com/${app.id}`
                    return {
                        name: user.name,
                        image: user.image,
                        _id: user._id,
                        id: user._id,
                        email: user.email_full,
                        app_image: user.app,
                        app: url,
                        app_name: user.from,
                        notification_id: user.notification_id
                    }
                });



            if (!people.length) return swal('Sending Message', 'Please enter a message', 'warning');
            setLoading(true);

            if (message) {
                const body = {
                    title, message, url,
                    button1, button2, buttonURL1, buttonURL2,
                    people
                }
                const res = await API.PostAPI(`/api/notifications/send/messages`, body);
                swal('Sending Notifications', res.message, res.result ? 'success' : 'error');
            }

            if (messageEmail) {
                const res2 = await API.PostAPI(`/api/email/send`, { html: messageEmail, emails: people.map(p => p.email), subject: titleEmail });
                swal('Sending Email', res2.message, res2.result ? 'success' : 'error');
            }

            setShowMessenger(false)

        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const filter = useCallback((user) => {
        if (currentApp !== "-1") {
            const name1 = user.from.replace(/\s/gmi, '').toLowerCase();
            const name2 = currentApp.replace(/\s/gmi, '').toLowerCase();
            if (name1 !== name2) return false;
        }
        if (search.replace(/\s/gmi) === '') return true;
        return user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 || user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }, [search, currentApp]);


    return (
        <SideBar>
            <Container fluid>
                <div style={{ display: "flex" }}>
                    <TextField size="small" style={{ width: 400, marginLeft: 10 }} label={<div><FaSearch />Search</div>} value={search} onChange={e => setSearch(e.target.value)} />
                    <Button size="small"><FaSearch /> Search</Button>
                    <FormControl>
                        <InputLabel id="dashboard-apps">Apps</InputLabel>
                        <Select
                            labelId="dashboard-apps"
                            label="Apps"
                            size="small"
                            style={{ width: 300, height: 40 }}
                            value={currentApp}
                            onChange={(e) => setCurrentApp(e.target.value)}
                        >
                            <MenuItem value="-1">All Apps</MenuItem>
                            {apps.sort((a, b) => a.name.localeCompare(b.name)).map((app) => (
                                <MenuItem key={app._id} value={app.name.replace(/\s/gim, '').toLowerCase()}> {app.name.toUpperCase()} </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button size="sm" variant="contained" color="primary" onClick={() => setShowMessenger(true)}><BsSendFill style={{ marginRight: 10 }} /> Send Notification or Email</Button>
                </div>
                <div style={{ width: "100%", height: "90vh" }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={allUsers.map((person, index) => {
                            return {
                                ...person,
                                id: person._id,
                                number: (index + 1)
                            }
                        }).filter(filter)}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        checkboxSelection={true}
                        selectionModel={selectionModel}
                        onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                    />
                </div>
            </Container>
            <Modal className="centralise" style={{ width: "100%" }} show={showMessenger} onHide={() => setShowMessenger(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><h6>Send Notifications To {selectionModel.length}</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: "100%" }}>
                    <TextField size="small" value={title} label="Title" onChange={e => setTitle(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                    <TextField multiple value={message} label="Write Your Message Here..." onChange={e => setMessage(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                    <TextField size="small" value={url} label="URL when notification is clicked" onChange={e => setUrl(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                    {/* <h6 style={{ color: "grey", margin: 10 }}>Buttons</h6>
                    <Row xs={2}>
                        <Col>
                            <TextField size="small" value={button1} label="Button 1" onChange={e => setButton1(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                        </Col>
                        <Col>
                            <TextField size="small" value={buttonURL1} label="Button 1 Link" onChange={e => setButtonURL1(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                        </Col>
                    </Row>
                    <Row xs={2}>
                        <Col>
                            <TextField size="small" value={button2} label="Button 2" onChange={e => setButton2(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                        </Col>
                        <Col>
                            <TextField size="small" value={buttonURL2} label="Button 2 Link" onChange={e => setButtonURL2(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                        </Col>
                    </Row> */}

                    <br />
                    <h5>Email</h5>
                    <h6><i>To Send Emails update your SMTP Settings <Link to="/settings-email">here.</Link></i></h6>
                    <hr />
                    <TextField size="small" value={titleEmail} label="Email Subject" onChange={e => setTitleEmail(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />
                    <TextField multiple value={messageEmail} label="Write Your Email Message Here..." onChange={e => setMessageEmail(e.target.value)} style={{ width: "100%", marginBottom: 10 }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="contained" color="inherit" onClick={() => setShowMessenger(false)}>CANCEL</Button>
                    <Button disabled={loading || !selectionModel.length} variant="contained" color="primary" onClick={onSendNotification}><BsSendFill />{!selectionModel.length ? "Select Someone First" : "SEND"}</Button>
                </Modal.Footer>
            </Modal>
        </SideBar>
    )
}