import { useState } from 'react'
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import { Button, Container, Form } from 'react-bootstrap';
import { TextField } from '@mui/material';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
const logo = "../images/logo.png";


function PageSignUpEmail() {
    const [loading, setLoading] = useState(false);

    const onEmail = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const user = {
                name: e.target.name.value,
                email: e.target.email.value,
            }

            const res = await API.PostAPI('/api/email/create', user);
            if (res.result) {
                swal('Sign In', res.message, 'success');
            } else {
                swal('Sign In', 'Something happened. Check your internet connection', 'info');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container style={{ paddingTop: 40, paddingBottom: 40 }} className="centralise" fluid>

            <img src={logo} alt="logo" width={250} />
            <h2>Sign Up</h2>
            {loading ?
                <div className="app"><AnimationLoading width={250} title="Loading" /></div>

                :
                <div>
                    <Form onSubmit={onEmail}>
                        <TextField style={{ width: 400 }} label="Name" type="name" required name="name" aria-label="name" />
                        <br /><br />
                        <TextField style={{ width: 400 }} label="Your Email" type="email" required name="email" aria-label="email" />
                        <br /> <br />
                        <Button style={{ width: 400 }} disabled={loading} type="submit" variant="light" size="lg" className="hover dropShadow">
                            <strong>Create Account</strong>
                        </Button>
                    </Form>

                    <br />
                    <h4>I Already Have an <Link to="/">Account</Link></h4>
                </div>
            }

            <br /><br /><br /><br />
        </Container>
    )
}

export default PageSignUpEmail;
