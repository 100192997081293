import React, { useContext, useEffect, useState } from 'react';
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as API from '../utils/api';

//http://localhost:3000/oauth?appToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImluZm8ubXlzb2NpYWwzNjBAZ21haWwuY29tIiwidWlkIjoiNjI2NmI2ZGE3NTUwMTA0NzIwNGRiYWFmIiwiaWF0IjoxNjk5NjIyMDU1fQ.zOaXrIUsEVd1_SppGMW2_xJkl3htnzBSBmgKCa9NsSw&appName=FBGroupMaster


// ProSideBar
import {
	ProSidebar,
	Menu,
	MenuItem,
	SubMenu,
	SidebarHeader,
	SidebarFooter,
	SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

// https://react-icons.github.io/react-icons/icons?name=ai
import {
	FaAngleDoubleRight,
	FaAngleDoubleLeft,
	FaBandcamp,
	FaUserCircle
} from 'react-icons/fa';
import { BiNetworkChart } from 'react-icons/bi';
import {
	AiTwotoneApi,
	AiFillAppstore,
} from 'react-icons/ai';
import { RiLogoutCircleRFill } from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import { BiBell, BiStats } from 'react-icons/bi';
import { IoIosPeople, IoIosSettings } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
	ContextCollapse,
	ContextSetCollapse,
	ContextSetUser,
	ContextUser,
} from '../App';
import swal from 'sweetalert';
import MobileBar from "../components/MobileBar"; 
import { AnimationLoading } from './Lottie';

const logo = "/images/logo.png";
 
const SideBar = (props) => {


	const router = useNavigate();
	const iconSize = 30;

	const user = useContext(ContextUser);
	const setUser = useContext(ContextSetUser);
	const collapse = useContext(ContextCollapse);
	const setCollapse = useContext(ContextSetCollapse);
	const [size, setSize] = useState(800);


	useEffect(() => {
		const updateSize = () => setSize(window.screen.width)
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);



	const onLogout = () => {
		setUser(null);
		Cookies.set(API.REACT_APP_COOKIE_KEY, '');
		router.push('/');
		swal('BOS Dashboard', 'Logout Successful', 'success');
	};

	if (size <= 800) {
		return (
			<div>
				<main
					style={{
						zIndex: 0,
						position: 'absolute',
						top: 5,
						// height: '80%',
						width: '100%',
						overflowY: 'visible',
					}}
				>
					{props.children}
					<MobileBar />
				</main>
			</div>
		)
	}

	if (!user) return (
		<Container style={{ paddingTop: 140, paddingBottom: 40 }} className="centralise" fluid>
			<img src={logo} alt="logo" width={200} height={200} />
			<h1>BOS Dashboard</h1>
			<h4>Logging In</h4>
			<AnimationLoading width={200} title="Loading" />
		</Container>
	)

	return (
		<>
			<ProSidebar
				style={{ position: 'fixed' }}
				collapsed={collapse}
				image="https://images.pexels.com/photos/2387877/pexels-photo-2387877.jpeg?cs=srgb&dl=pexels-sam-kolder-2387877.jpg&fm=jpg"
			>
				<SidebarHeader>
					<Menu iconShape="round">
						<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Apps"><div><h6><strong>{user.name} {user.email}</strong><h6>Click to Collapse/Expand</h6></h6></div></Tooltip>}>
							<MenuItem onClick={() => setCollapse(!collapse)} icon={<IconContext.Provider value={{ color: "#FFFFFF", size: iconSize }}>
								{/* <img
									src={user.image}
									alt="BOS"
									width={iconSize + 20}
									height={iconSize + 20}
									onClick={() => setCollapse(!collapse)}
								/> */}
								<FaUserCircle size={20} />
							</IconContext.Provider>}>{user.name} ({user.email})</MenuItem>
						</OverlayTrigger>
					</Menu>
				</SidebarHeader>

				<SidebarContent>
					<Menu iconShape="square">
						<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Apps"><h6>Apps</h6></Tooltip>}>
							<MenuItem onClick={() => router('/apps')} icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><AiFillAppstore /></IconContext.Provider>}>Apps</MenuItem>
						</OverlayTrigger>

						<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Users"><h6>BOS App Users</h6></Tooltip>}>
							<MenuItem onClick={() => router('/users')} icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><IoIosPeople /></IconContext.Provider>}>Affilate Network</MenuItem>
						</OverlayTrigger>

						<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Affiliate"><h6>Affiliate Network</h6></Tooltip>}>
							<MenuItem onClick={() => router('/affiliates')} icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><BiNetworkChart /></IconContext.Provider>}>Affilate Network</MenuItem>
						</OverlayTrigger>
 
						<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Statistics"><h6>Statistics</h6></Tooltip>}>
							<MenuItem onClick={() => router('/statistics')} icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><BiStats /></IconContext.Provider>}>Statistics</MenuItem>
						</OverlayTrigger>

						<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Notifications"><h6>Notifications</h6></Tooltip>}>
							<MenuItem onClick={() => router('/notifications')} icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><BiBell /></IconContext.Provider>}>Notifications</MenuItem>
						</OverlayTrigger>
					</Menu>
				</SidebarContent>

				<SidebarFooter>
					<Menu iconShape="round">
						<SubMenu title="Settings" icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><IoIosSettings /></IconContext.Provider>}>
							<MenuItem onClick={() => router("/settings-email")}>Emailer</MenuItem>
							<MenuItem onClick={() => { }}>Integrations</MenuItem>
							<MenuItem onClick={() => { }}>Security</MenuItem>
						</SubMenu>

						<SubMenu title="About Us" icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><AiTwotoneApi /></IconContext.Provider>}>
							<MenuItem><a target="_blank" rel="noreferrer" href="https://app.mysocial360.com/terms">Terms Of Service</a></MenuItem>
							<MenuItem><a target="_blank" rel="noreferrer" href="https://app.mysocial360.com/privacypolicy">Privacy Policy</a></MenuItem>
						</SubMenu>


						<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="Logout"><h6>Logout</h6></Tooltip>}>
							<MenuItem onClick={onLogout} icon={<IconContext.Provider value={{ color: "#DDDDDD", size: iconSize }}><RiLogoutCircleRFill /></IconContext.Provider>}>Logout</MenuItem>
						</OverlayTrigger>


						<OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="collapse"><h6>{collapse ? "Expand" : "Collapse"}</h6></Tooltip>}>
							<MenuItem onClick={() => setCollapse(!collapse)} icon={<IconContext.Provider value={{ color: "#FFFFFF", size: iconSize }}>{collapse ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}</IconContext.Provider>}>{collapse ? "Expand" : "Collapse"}</MenuItem>
						</OverlayTrigger>
					</Menu>
				</SidebarFooter>
			</ProSidebar>
			<main
				style={{
					zIndex: 0,
					position: 'absolute',
					top: 5,
					left: 90,
					height: '80%',
					width: '90vw',
					overflowY: 'visible',
				}}
			>
				{props.children}
			</main>
		</>
	);
};

export default SideBar;
