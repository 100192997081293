import React from 'react'
import { AnimationComingSoon } from '../components/Lottie'
import SideBar from '../components/SideBar'

function PageStatistics() {
  return (
    <SideBar><AnimationComingSoon/></SideBar>
  )
}

export default PageStatistics